import React, { useState, useRef } from 'react';
import { Save, Plus, X, Download, Upload } from 'lucide-react';
import { collection, addDoc, writeBatch, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Student, Board } from '../types';
import { generateExcelTemplate, parseExcelData } from '../utils/excelUtils';
import toast from 'react-hot-toast';

const BOARDS: Board[] = ['IGCSE', 'CBSE', 'SSC', 'NIOS', 'IB', 'AS/A IBDP', 'Others'];

const initialStudentData = {
  name: '',
  school: '',
  division: '',
  grade: '',
  board: 'IGCSE' as Board,
  whatsappLink: ''
};

export default function StudentForm() {
  const [students, setStudents] = useState<Omit<Student, 'id'>[]>([initialStudentData]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const addStudent = () => {
    setStudents([...students, { ...initialStudentData }]);
  };

  const removeStudent = (index: number) => {
    setStudents(students.filter((_, i) => i !== index));
  };

  const updateStudent = (index: number, field: keyof Omit<Student, 'id'>, value: string) => {
    const updatedStudents = [...students];
    updatedStudents[index] = { ...updatedStudents[index], [field]: value };
    setStudents(updatedStudents);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      const parsedStudents = await parseExcelData(file);
      
      // Use batched writes for better performance
      const batch = writeBatch(db);
      const studentsRef = collection(db, 'students');

      parsedStudents.forEach((student) => {
        const docRef = doc(studentsRef);
        batch.set(docRef, student);
      });

      await batch.commit();
      toast.success(`Successfully added ${parsedStudents.length} students`);
      
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error: any) {
      console.error('Error uploading students:', error);
      toast.error(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await Promise.all(
        students.map(student => addDoc(collection(db, 'students'), student))
      );
      toast.success(`${students.length} student(s) added successfully`);
      setStudents([initialStudentData]);
    } catch (error) {
      toast.error('Failed to add students');
      console.error('Error adding students:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-6">
          <div className="flex items-center">
            <Plus className="w-5 h-5 mr-2 text-secondary" />
            <h2 className="text-xl font-bold text-secondary">Add New Students</h2>
          </div>
          <div className="flex flex-wrap gap-3">
            <button
              type="button"
              onClick={() => generateExcelTemplate()}
              className="btn-primary flex items-center bg-accent"
            >
              <Download className="w-4 h-4 mr-2" />
              Download Template
            </button>
            <label className="btn-primary flex items-center cursor-pointer">
              <Upload className="w-4 h-4 mr-2" />
              {isUploading ? 'Uploading...' : 'Upload Excel'}
              <input
                ref={fileInputRef}
                type="file"
                accept=".xlsx,.xls"
                className="hidden"
                onChange={handleFileUpload}
                disabled={isUploading}
              />
            </label>
            <button
              type="button"
              onClick={addStudent}
              className="btn-primary flex items-center"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Manually
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {students.map((student, index) => (
            <div key={index} className="relative border border-gray-200 rounded-lg p-4">
              {students.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeStudent(index)}
                  className="absolute top-2 right-2 p-1 text-gray-400 hover:text-red-500"
                >
                  <X className="w-5 h-5" />
                </button>
              )}
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Student Name
                  </label>
                  <input
                    type="text"
                    value={student.name}
                    onChange={(e) => updateStudent(index, 'name', e.target.value)}
                    className="input-field"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    School
                  </label>
                  <input
                    type="text"
                    value={student.school}
                    onChange={(e) => updateStudent(index, 'school', e.target.value)}
                    className="input-field"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Board
                  </label>
                  <select
                    value={student.board}
                    onChange={(e) => updateStudent(index, 'board', e.target.value as Board)}
                    className="input-field"
                    required
                  >
                    {BOARDS.map(board => (
                      <option key={board} value={board}>{board}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Grade
                  </label>
                  <input
                    type="text"
                    value={student.grade}
                    onChange={(e) => updateStudent(index, 'grade', e.target.value)}
                    className="input-field"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Division
                  </label>
                  <input
                    type="text"
                    value={student.division}
                    onChange={(e) => updateStudent(index, 'division', e.target.value)}
                    className="input-field"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    WhatsApp Group Link
                  </label>
                  <input
                    type="url"
                    value={student.whatsappLink}
                    onChange={(e) => updateStudent(index, 'whatsappLink', e.target.value)}
                    className="input-field"
                    required
                  />
                </div>
              </div>
            </div>
          ))}

          {students.length > 0 && (
            <button
              type="submit"
              className="w-full mt-6 btn-primary flex items-center justify-center"
            >
              <Save className="w-5 h-5 mr-2" />
              Save {students.length > 1 ? 'All Students' : 'Student'}
            </button>
          )}
        </form>
      </div>
    </div>
  );
}