import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, Timestamp, orderBy, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import type { AttendanceLog } from '../types';

export function useAttendance(userId: string) {
  const [lastInTime, setLastInTime] = useState<Date | null>(null);
  const [lastOutTime, setLastOutTime] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!userId) return;

    const loadTodayAttendance = async () => {
      try {
        const today = format(new Date(), 'yyyy-MM-dd');
        const attendanceRef = collection(db, 'attendance');
        
        // Query for IN record
        const inQuery = query(
          attendanceRef,
          where('teacherId', '==', userId),
          where('date', '==', today),
          where('type', '==', 'IN'),
          limit(1)
        );

        // Query for OUT record
        const outQuery = query(
          attendanceRef,
          where('teacherId', '==', userId),
          where('date', '==', today),
          where('type', '==', 'OUT'),
          limit(1)
        );

        const [inSnapshot, outSnapshot] = await Promise.all([
          getDocs(inQuery),
          getDocs(outQuery)
        ]);

        if (!inSnapshot.empty) {
          const inData = inSnapshot.docs[0].data();
          setLastInTime(inData.timestamp.toDate());
        }

        if (!outSnapshot.empty) {
          const outData = outSnapshot.docs[0].data();
          setLastOutTime(outData.timestamp.toDate());
        }
      } catch (error) {
        console.error('Error loading attendance:', error);
        toast.error('Failed to load attendance data');
      } finally {
        setIsLoading(false);
      }
    };

    loadTodayAttendance();
  }, [userId]);

  const handleAttendance = async (type: 'IN' | 'OUT') => {
    if (!userId) return;

    try {
      const now = new Date();
      const today = format(now, 'yyyy-MM-dd');

      // Check if attendance already marked
      const checkQuery = query(
        collection(db, 'attendance'),
        where('teacherId', '==', userId),
        where('date', '==', today),
        where('type', '==', type)
      );

      const checkSnapshot = await getDocs(checkQuery);
      if (!checkSnapshot.empty) {
        toast.error(`You've already marked ${type} for today`);
        return;
      }

      // For OUT, check if IN exists
      if (type === 'OUT' && !lastInTime) {
        toast.error("You need to mark 'IN' before marking 'OUT'");
        return;
      }

      const attendanceData: Omit<AttendanceLog, 'id'> = {
        teacherId: userId,
        type,
        timestamp: Timestamp.fromDate(now),
        date: today
      };

      await addDoc(collection(db, 'attendance'), attendanceData);
      
      if (type === 'IN') {
        setLastInTime(now);
        // Also update teacherAttendance collection
        const teacherAttendanceData = {
          teacherId: userId,
          date: Timestamp.fromDate(now),
          punchInTime: Timestamp.fromDate(now)
        };
        
        // Check if record exists for today
        const teacherAttendanceQuery = query(
          collection(db, 'teacherAttendance'),
          where('teacherId', '==', userId),
          where('date', '==', Timestamp.fromDate(new Date(today)))
        );
        
        const teacherAttendanceSnapshot = await getDocs(teacherAttendanceQuery);
        if (teacherAttendanceSnapshot.empty) {
          await addDoc(collection(db, 'teacherAttendance'), teacherAttendanceData);
        }
      } else {
        setLastOutTime(now);
      }
      
      toast.success(`Marked ${type} successfully`);
    } catch (error) {
      console.error('Error marking attendance:', error);
      toast.error('Failed to mark attendance');
    }
  };

  return {
    lastInTime,
    lastOutTime,
    handleAttendance,
    isLoading
  };
}