import { useState, useEffect } from 'react';
import { 
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { User } from '../types';
import toast from 'react-hot-toast';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          // Special handling for admin user
          if (firebaseUser.email === 'admin@questo.com') {
            setUser({
              id: firebaseUser.uid,
              email: firebaseUser.email,
              name: 'Admin',
              role: 'admin'
            });
          } else {
            const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
            if (userDoc.exists()) {
              setUser({
                id: firebaseUser.uid,
                email: firebaseUser.email!,
                ...userDoc.data()
              } as User);
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          toast.error('Error loading user data');
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Special handling for admin user
      if (email === 'admin@questo.com') {
        if (password !== 'libral@500') {
          await firebaseSignOut(auth);
          toast.error('Invalid admin password');
          return;
        }
        setUser({
          id: userCredential.user.uid,
          email: userCredential.user.email!,
          name: 'Admin',
          role: 'admin'
        });
      } else {
        // For teachers, get their data from Firestore
        const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
        if (userDoc.exists()) {
          setUser({
            id: userCredential.user.uid,
            email: userCredential.user.email!,
            ...userDoc.data()
          } as User);
          toast.success('Signed in successfully');
        } else {
          await firebaseSignOut(auth);
          toast.error('User not found');
        }
      }
    } catch (error: any) {
      console.error('Sign in error:', error);
      let errorMessage = 'An error occurred during sign in';
      
      // Handle specific Firebase Auth errors
      switch (error.code) {
        case 'auth/invalid-email':
          errorMessage = 'Invalid email address format';
          break;
        case 'auth/user-not-found':
          errorMessage = 'No account found with this email';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Incorrect password';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many failed attempts. Please try again later';
          break;
        case 'auth/user-disabled':
          errorMessage = 'This account has been disabled';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Network error. Please check your internet connection';
          break;
      }
      
      toast.error(errorMessage);
      throw error;
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      toast.success('Signed out successfully');
    } catch (error) {
      console.error('Sign out error:', error);
      toast.error('Failed to sign out');
      throw error;
    }
  };

  return {
    user,
    loading,
    signIn,
    signOut
  };
}