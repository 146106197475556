import * as XLSX from 'xlsx';
import { Student, Board } from '../types';
import toast from 'react-hot-toast';

const VALID_BOARDS: Board[] = ['IGCSE', 'CBSE', 'SSC', 'NIOS', 'IB', 'AS/A IBDP', 'Others'];

export function generateExcelTemplate() {
  const template = [
    {
      'Student Name': 'John Doe',
      'School': 'Example School',
      'Division': 'A',
      'Grade': '10',
      'Board': 'IGCSE',
      'WhatsApp Group Link': 'https://chat.whatsapp.com/example'
    }
  ];

  const ws = XLSX.utils.json_to_sheet(template);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Students Template');

  // Auto-size columns
  const colWidths = Object.keys(template[0]).map(key => ({
    wch: Math.max(key.length, String(template[0][key]).length)
  }));
  ws['!cols'] = colWidths;

  XLSX.writeFile(wb, 'students_template.xlsx');
}

export function parseExcelData(file: File): Promise<Omit<Student, 'id'>[]> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet) as any[];

        const students = jsonData.map((row, index) => {
          // Validate required fields
          if (!row['Student Name'] || !row['School'] || !row['Grade'] || !row['Board']) {
            throw new Error(`Row ${index + 1} is missing required fields`);
          }

          // Validate board
          if (!VALID_BOARDS.includes(row['Board'])) {
            throw new Error(`Invalid board "${row['Board']}" in row ${index + 1}`);
          }

          return {
            name: String(row['Student Name']).trim(),
            school: String(row['School']).trim(),
            division: String(row['Division'] || '').trim(),
            grade: String(row['Grade']).trim(),
            board: row['Board'] as Board,
            whatsappLink: String(row['WhatsApp Group Link'] || '').trim()
          };
        });

        resolve(students);
      } catch (error: any) {
        reject(new Error(`Excel parsing error: ${error.message}`));
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file'));
    };

    reader.readAsArrayBuffer(file);
  });
}