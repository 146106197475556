import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, DropResult } from '@hello-pangea/dnd';
import { db, storage } from '../lib/firebase';
import { collection, addDoc, getDocs, updateDoc, doc, Timestamp, query, orderBy, where, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Grid,
  IconButton,
  Chip,
  CircularProgress,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
  Stack,
  Snackbar,
  Alert,
  Link
} from '@mui/material';
import { 
  Add as AddIcon, 
  Download as DownloadIcon, 
  Upload as UploadIcon,
  Flag as FlagIcon,
  Label as LabelIcon,
  AccessTime as TimeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DroppableWrapper from './DroppableWrapper';

interface Task {
  id: string;
  title: string;
  description: string;
  points: number;
  deadline: Date;
  status: 'not_started' | 'in_progress' | 'completed' | 'blocked';
  priority: 'low' | 'medium' | 'high' | 'urgent';
  category: string;
  assignedTo?: string;
  createdBy: string;
  createdByName: string;
  fileUrl?: string;
  fileName?: string;
  submissionUrl?: string;
  submissionFileName?: string;
  progress: number;
  notInterestedTeachers?: string[];
  checklist: { id: string; text: string; completed: boolean; }[];
}

const CATEGORIES = ['To Do', 'In Progress', 'Completed', 'Blocked'];
const PRIORITIES = [
  { value: 'low', label: 'Low', color: '#A8E6CF', gradient: 'linear-gradient(135deg, #A8E6CF 0%, #DCEDC1 100%)' },
  { value: 'medium', label: 'Medium', color: '#FFD3B6', gradient: 'linear-gradient(135deg, #FFD3B6 0%, #FFDDD2 100%)' },
  { value: 'high', label: 'High', color: '#FFAAA5', gradient: 'linear-gradient(135deg, #FFAAA5 0%, #FFC3A0 100%)' },
  { value: 'urgent', label: 'Urgent', color: '#FF8B94', gradient: 'linear-gradient(135deg, #FF8B94 0%, #FFA7A7 100%)' }
];

const CATEGORY_STYLES = {
  'To Do': {
    gradient: 'linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)',
    shadow: '0 4px 15px rgba(33, 150, 243, 0.15)'
  },
  'In Progress': {
    gradient: 'linear-gradient(135deg, #FFF3E0 0%, #FFE0B2 100%)',
    shadow: '0 4px 15px rgba(255, 152, 0, 0.15)'
  },
  'Completed': {
    gradient: 'linear-gradient(135deg, #E8F5E9 0%, #C8E6C9 100%)',
    shadow: '0 4px 15px rgba(76, 175, 80, 0.15)'
  },
  'Blocked': {
    gradient: 'linear-gradient(135deg, #FFEBEE 0%, #FFCDD2 100%)',
    shadow: '0 4px 15px rgba(244, 67, 54, 0.15)'
  }
};

const Tasks: React.FC = () => {
  const { currentUser, isAdmin } = useAuth();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const [taskMap, setTaskMap] = useState<{[key: string]: Task}>({});
  const [openDialog, setOpenDialog] = useState(false);
  const [editTask, setEditTask] = useState<Task | null>(null);
  const [editingPoints, setEditingPoints] = useState<{id: string, points: number} | null>(null);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [taskDetailsOpen, setTaskDetailsOpen] = useState(false);

  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
    points: 0,
    deadline: new Date(),
    priority: 'medium',
    category: 'To Do',
    checklist: [],
    progress: 0,
  });
  const [editedTask, setEditedTask] = useState({
    title: '',
    description: '',
    points: 0,
    deadline: new Date(),
    priority: 'medium',
    checklist: [],
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [submissionFile, setSubmissionFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [checklistItem, setChecklistItem] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });
  const [users, setUsers] = useState<{ [key: string]: { name: string } }>({});

  const canEditTask = (task: Task) => {
    return isAdmin || task.createdBy === currentUser?.uid;
  };

  const handleAssignTask = async (accept: boolean) => {
    if (!selectedTask || !currentUser) return;

    try {
      const taskToUpdate = taskMap[selectedTask.id];
      if (!taskToUpdate) {
        console.error('Task not found:', selectedTask.id);
        return;
      }

      const newStatus = accept ? 'in_progress' : 'not_started';
      const updatedTask = {
        ...taskToUpdate,
        status: newStatus
      };

      // Update local state immediately
      setTasks(prevTasks => 
        prevTasks.map(task => task.id === selectedTask.id ? updatedTask : task)
      );
      setTaskMap(prev => ({
        ...prev,
        [selectedTask.id]: updatedTask
      }));

      // Update Firebase
      const taskRef = doc(db, 'tasks', selectedTask.id);
      await updateDoc(taskRef, {
        status: newStatus
      });
    } catch (error) {
      console.error('Error updating task status:', error);
      // Revert local state on error by refetching
      fetchTasks();
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersData: { [key: string]: { name: string } } = {};
        usersSnapshot.forEach((doc) => {
          usersData[doc.id] = { name: doc.data().name };
        });
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setLoading(true);
        let tasksQuery = query(collection(db, 'tasks'), orderBy('deadline'));
        const querySnapshot = await getDocs(tasksQuery);
        const fetchedTasks = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          deadline: doc.data().deadline.toDate(),
          status: doc.data().status || 'not_started',
          category: doc.data().category || 'To Do'
        })) as Task[];
        
        // Create a map for quick task lookup
        const taskMapObj = fetchedTasks.reduce((acc, task) => {
          if (task.id) {
            acc[task.id] = task;
          }
          return acc;
        }, {} as {[key: string]: Task});
        
        setTasks(fetchedTasks);
        setTaskMap(taskMapObj);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [currentUser]);

  const handleCreateTask = async () => {
    if (!newTask.title.trim()) {
      setSnackbar({
        open: true,
        message: 'Please enter a task title',
        severity: 'error'
      });
      return;
    }

    try {
      setUploadProgress(true);
      let fileUrl = '';
      let fileName = '';

      if (selectedFile) {
        try {
          // Debug authentication state
          console.log('Current user:', currentUser);
          console.log('Auth state:', {
            isAuthenticated: !!currentUser,
            uid: currentUser?.uid,
            email: currentUser?.email
          });

          // Check if user is authenticated
          if (!currentUser?.uid) {
            throw new Error('You must be logged in to upload files');
          }

          // Create a unique filename to prevent collisions
          const uniqueFileName = `${Date.now()}-${selectedFile.name}`;
          const filePath = `tasks/${currentUser.uid}/${uniqueFileName}`;
          console.log('Attempting to upload to:', filePath);
          
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('path', filePath);
          formData.append('metadata', JSON.stringify({
            taskId: '',
            userId: currentUser.uid,
            fileName: selectedFile.name
          }));

          console.log('Sending file upload request to proxy server...');
          const response = await fetch('http://localhost:3007/upload', {
            method: 'POST',
            body: formData
          });

          console.log('Response status:', response.status);
          const responseText = await response.text();
          console.log('Response text:', responseText);

          if (!response.ok) {
            let errorMessage;
            try {
              const errorData = JSON.parse(responseText);
              errorMessage = errorData.error || errorData.message || 'Upload failed';
            } catch {
              errorMessage = 'Upload failed';
            }
            throw new Error(errorMessage);
          }

          const data = JSON.parse(responseText);
          fileUrl = data.url;
          fileName = selectedFile.name;
          console.log('Download URL obtained:', fileUrl);
        } catch (uploadError: any) {
          console.error('Upload error details:', {
            error,
            code: uploadError.code,
            message: uploadError.message,
            name: uploadError.name,
            stack: uploadError.stack,
            response: uploadError.response
          });

          let errorMessage = 'Error uploading file. Please try again.';
          
          if (uploadError.message === 'You must be logged in to upload files') {
            errorMessage = uploadError.message;
          } else if (uploadError.code === 'storage/unauthorized') {
            errorMessage = 'Storage access denied. Please contact your administrator to enable file uploads.';
          } else if (uploadError.message === 'File size exceeds 10MB limit') {
            errorMessage = uploadError.message;
          } else {
            errorMessage = 'Error uploading file. Please try again.';
          }

          setSnackbar({
            open: true,
            message: errorMessage,
            severity: 'error'
          });
          setUploadProgress(false);
          return;
        }
      }

      const taskData = {
        ...newTask,
        status: 'not_started',
        createdBy: currentUser?.uid,
        createdByName: users[currentUser?.uid]?.name || 'Unknown Teacher',
        assignedTo: isAdmin ? '' : currentUser?.uid,
        deadline: Timestamp.fromDate(newTask.deadline),
        fileUrl,
        fileName,
        createdAt: Timestamp.now(),
        progress: 0,
        checklist: newTask.checklist || [],
        category: 'To Do'
      };

      const docRef = await addDoc(collection(db, 'tasks'), taskData);
      
      // Update local state immediately
      const newTaskWithId = { 
        ...taskData, 
        id: docRef.id,
        deadline: newTask.deadline 
      } as Task;
      
      setTasks(prevTasks => [...prevTasks, newTaskWithId]);
      setTaskMap(prev => ({ ...prev, [docRef.id]: newTaskWithId }));

      setSnackbar({
        open: true,
        message: 'Task created successfully!',
        severity: 'success'
      });

      setOpenDialog(false);
      setNewTask({
        title: '',
        description: '',
        points: 0,
        deadline: new Date(),
        priority: 'medium',
        category: 'To Do',
        checklist: [],
        progress: 0,
      });
      setSelectedFile(null);
    } catch (error) {
      console.error('Error creating task:', error);
      setSnackbar({
        open: true,
        message: 'Error creating task. Please try again.',
        severity: 'error'
      });
    } finally {
      setUploadProgress(false);
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination || !result.draggableId) {
      return;
    }

    const { source, destination, draggableId } = result;
    
    if (source.droppableId !== destination.droppableId) {
      try {
        const taskToUpdate = taskMap[draggableId];
        if (!taskToUpdate) {
          console.error('Task not found:', draggableId);
          return;
        }

        const newStatus = destination.droppableId.toLowerCase().replace(' ', '_');
        const updatedTask = {
          ...taskToUpdate,
          category: destination.droppableId,
          status: newStatus
        };

        // Update local state immediately
        setTasks(prevTasks => 
          prevTasks.map(task => task.id === draggableId ? updatedTask : task)
        );
        setTaskMap(prev => ({
          ...prev,
          [draggableId]: updatedTask
        }));

        // Update Firebase
        const taskRef = doc(db, 'tasks', draggableId);
        await updateDoc(taskRef, {
          category: destination.droppableId,
          status: newStatus
        });
      } catch (error) {
        console.error('Error updating task status:', error);
        // Revert local state on error by refetching
        fetchTasks();
      }
    }
  };

  const handleEditClick = (task: Task) => {
    setEditTask(task);
    setEditedTask({
      title: task.title,
      description: task.description,
      points: task.points,
      deadline: task.deadline,
      priority: task.priority,
      checklist: task.checklist,
    });
  };

  const handleUpdateTask = async () => {
    if (!editTask) return;

    try {
      setUploadProgress(true);
      await updateDoc(doc(db, 'tasks', editTask.id), {
        title: editedTask.title,
        description: editedTask.description,
        points: editedTask.points,
        deadline: Timestamp.fromDate(editedTask.deadline),
        priority: editedTask.priority,
        checklist: editedTask.checklist,
      });

      // Update local state
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === editTask.id
            ? {
                ...task,
                ...editedTask,
              }
            : task
        )
      );
      setTaskMap(prev => ({
        ...prev,
        [editTask.id]: {
          ...prev[editTask.id],
          ...editedTask,
        }
      }));

      setSnackbar({
        open: true,
        message: 'Task updated successfully!',
        severity: 'success'
      });

      setEditTask(null);
    } catch (error) {
      console.error('Error updating task:', error);
      setSnackbar({
        open: true,
        message: 'Error updating task. Please try again.',
        severity: 'error'
      });
    } finally {
      setUploadProgress(false);
    }
  };

  const handleAddChecklistItem = () => {
    if (!checklistItem.trim()) return;
    
    setNewTask(prev => ({
      ...prev,
      checklist: [
        ...prev.checklist,
        { id: Date.now().toString(), text: checklistItem, completed: false }
      ]
    }));
    setChecklistItem('');
  };

  const getTasksByCategory = (category: string) => {
    return tasks.filter(task => 
      task.category === category && 
      task.id && 
      (!task.notInterestedTeachers?.includes(currentUser?.uid))
    );
  };

  const handleDeleteTask = async (taskId: string) => {
    if (!window.confirm('Are you sure you want to delete this task?')) {
      return;
    }
    try {
      await deleteDoc(doc(db, 'tasks', taskId));
      setTasks(tasks.filter(task => task.id !== taskId));
      setTaskMap(prev => {
        const newMap = { ...prev };
        delete newMap[taskId];
        return newMap;
      });
    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Failed to delete task. Please try again.');
    }
  };

  const handlePointsUpdate = async (taskId: string, newPoints: number) => {
    try {
      await updateDoc(doc(db, 'tasks', taskId), {
        points: newPoints
      });
      
      // Update local state
      setTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === taskId
            ? { ...task, points: newPoints }
            : task
        )
      );
      setTaskMap(prev => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          points: newPoints,
        }
      }));

      setSnackbar({
        open: true,
        message: 'Points updated successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating points:', error);
      setSnackbar({
        open: true,
        message: 'Error updating points. Please try again.',
        severity: 'error'
      });
    } finally {
      setEditingPoints(null);
    }
  };

  const handleTaskAssignment = async (task: Task) => {
    try {
      await updateDoc(doc(db, 'tasks', task.id), {
        assignedTo: currentUser?.id,
        category: 'In Progress',
        status: 'in_progress'
      });

      // Update local state
      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === task.id
            ? { ...t, assignedTo: currentUser?.id, category: 'In Progress', status: 'in_progress' }
            : t
        )
      );
      setTaskMap(prev => ({
        ...prev,
        [task.id]: {
          ...prev[task.id],
          assignedTo: currentUser?.id,
          category: 'In Progress',
          status: 'in_progress',
        }
      }));

      setSnackbar({
        open: true,
        message: 'Task assigned successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error assigning task:', error);
      setSnackbar({
        open: true,
        message: 'Error assigning task. Please try again.',
        severity: 'error'
      });
    } finally {
      setAssignDialogOpen(false);
      setSelectedTask(null);
    }
  };

  const handleNotInterested = async (task: Task) => {
    try {
      const notInterestedTeachers = task.notInterestedTeachers || [];
      await updateDoc(doc(db, 'tasks', task.id), {
        notInterestedTeachers: [...notInterestedTeachers, currentUser?.uid]
      });

      // Update local state
      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === task.id
            ? { ...t, notInterestedTeachers: [...notInterestedTeachers, currentUser?.uid] }
            : t
        )
      );
      setTaskMap(prev => ({
        ...prev,
        [task.id]: {
          ...prev[task.id],
          notInterestedTeachers: [...notInterestedTeachers, currentUser?.uid]
        }
      }));

      setSnackbar({
        open: true,
        message: 'Task removed from your view',
        severity: 'info'
      });
      setTaskDetailsOpen(false);
    } catch (error) {
      console.error('Error updating task:', error);
      setSnackbar({
        open: true,
        message: 'Error updating task. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleFileUpload = async (taskId: string, file: File, isSubmission: boolean = false) => {
    if (!currentUser?.uid) {
      setSnackbar({
        open: true,
        message: 'Please sign in to upload files',
        severity: 'error'
      });
      return;
    }

    try {
      setUploadProgress(true);
      
      const formData = new FormData();
      formData.append('file', file);
      formData.append('path', `uploads/${currentUser.uid}/${taskId}/${file.name}`);
      formData.append('metadata', JSON.stringify({
        taskId,
        userId: currentUser.uid,
        fileName: file.name
      }));

      console.log('Sending file upload request to proxy server...');
      const response = await fetch('http://localhost:3007/upload', {
        method: 'POST',
        body: formData
      });

      console.log('Response status:', response.status);
      const responseText = await response.text();
      console.log('Response text:', responseText);

      if (!response.ok) {
        let errorMessage;
        try {
          const errorData = JSON.parse(responseText);
          errorMessage = errorData.error || errorData.message || 'Upload failed';
        } catch {
          errorMessage = 'Upload failed';
        }
        throw new Error(errorMessage);
      }

      const data = JSON.parse(responseText);
      
      const updateData = isSubmission 
        ? { 
            submissionUrl: data.url, 
            submissionFileName: file.name 
          }
        : { 
            fileUrl: data.url, 
            fileName: file.name 
          };

      // Update Firestore
      await updateDoc(doc(db, 'tasks', taskId), updateData);

      // Update local state
      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === taskId
            ? { ...t, ...updateData }
            : t
        )
      );

      if (editTask?.id === taskId) {
        setEditTask(prev => prev ? { ...prev, ...updateData } : null);
      }

      setTaskMap(prev => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          ...updateData
        }
      }));

      setSnackbar({
        open: true,
        message: `${isSubmission ? 'Work submitted' : 'File uploaded'} successfully!`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Upload error:', error);
      setSnackbar({
        open: true,
        message: 'Error uploading file. Please try again.',
        severity: 'error'
      });
    } finally {
      setUploadProgress(false);
    }
  };

  const handleFileRemoval = async (taskId: string, isSubmission: boolean = false) => {
    try {
      const updateData = isSubmission 
        ? { 
            submissionUrl: null, 
            submissionFileName: null 
          }
        : { 
            fileUrl: null, 
            fileName: null 
          };
      
      await updateDoc(doc(db, 'tasks', taskId), updateData);

      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === taskId
            ? { ...t, ...updateData }
            : t
        )
      );

      if (editTask?.id === taskId) {
        setEditTask(prev => prev ? { ...prev, ...updateData } : null);
      }

      setTaskMap(prev => ({
        ...prev,
        [taskId]: {
          ...prev[taskId],
          ...updateData
        }
      }));

      setSnackbar({
        open: true,
        message: 'File removed successfully!',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error removing file:', error);
      setSnackbar({
        open: true,
        message: 'Error removing file. Please try again.',
        severity: 'error'
      });
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box p={3} sx={{ 
      background: 'linear-gradient(135deg, #FAFAFA 0%, #F5F5F5 100%)', 
      minHeight: '100vh',
      fontFamily: 'sweater-school, sans-serif'
    }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" sx={{ 
          color: '#2196F3', 
          fontWeight: 'bold',
          fontFamily: 'sweater-school, sans-serif',
          fontSize: '2.5rem'
        }}>
          Tasks
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{
            background: 'linear-gradient(135deg, #90CAF9 0%, #64B5F6 100%)',
            boxShadow: '0 4px 15px rgba(33, 150, 243, 0.2)',
            transition: 'all 0.3s ease',
            fontFamily: 'sweater-school, sans-serif',
            color: '#000000',
            fontSize: '1rem',
            '&:hover': {
              transform: 'translateY(-2px)',
              background: 'linear-gradient(135deg, #64B5F6 0%, #42A5F5 100%)',
              boxShadow: '0 6px 20px rgba(33, 150, 243, 0.25)',
            }
          }}
        >
          New Task
        </Button>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={3}>
          {CATEGORIES.map(category => (
            <Grid item xs={12} sm={6} md={3} key={category}>
              <Card sx={{ 
                p: 2, 
                height: '100%',
                background: CATEGORY_STYLES[category].gradient,
                boxShadow: CATEGORY_STYLES[category].shadow,
                borderRadius: '16px',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: CATEGORY_STYLES[category].shadow.replace('15px', '25px'),
                }
              }}>
                <Typography variant="h6" mb={2} sx={{ 
                  color: '#3366cc',
                  fontWeight: 'bold',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                  fontFamily: 'sweater-school, sans-serif',
                  textAlign: 'center',
                  fontSize: '1.5rem'
                }}>
                  {category}
                </Typography>
                <DroppableWrapper droppableId={category}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{ minHeight: 200 }}
                    >
                      {getTasksByCategory(category).map((task, index) => (
                        <Draggable 
                          key={task.id} 
                          draggableId={task.id} 
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Card
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{ 
                                p: 2, 
                                mb: 2, 
                                background: snapshot.isDragging 
                                  ? 'rgba(255, 255, 255, 1)' 
                                  : 'rgba(255, 255, 255, 0.95)',
                                backdropFilter: 'blur(10px)',
                                borderRadius: '12px',
                                boxShadow: snapshot.isDragging
                                  ? '0 8px 24px rgba(0, 0, 0, 0.15)'
                                  : '0 4px 15px rgba(0, 0, 0, 0.05)',
                                transition: 'all 0.3s ease',
                                fontFamily: 'sweater-school, sans-serif',
                                cursor: category === 'To Do' && !task.assignedTo ? 'pointer' : 'grab',
                                transform: snapshot.isDragging ? 'scale(1.02)' : 'scale(1)',
                                zIndex: snapshot.isDragging ? 1 : 0,
                                touchAction: category === 'To Do' && !task.assignedTo ? 'manipulation' : 'none',
                                WebkitTapHighlightColor: 'transparent',
                                userSelect: 'none',
                                '&:hover': category === 'To Do' && !task.assignedTo ? {
                                  transform: 'scale(1.02)',
                                  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.08)',
                                  background: 'rgba(255, 255, 255, 1)',
                                } : {},
                                '&:active': {
                                  cursor: category === 'To Do' && !task.assignedTo ? 'pointer' : 'grabbing',
                                  transform: snapshot.isDragging ? 'scale(1.02)' : 'scale(0.98)',
                                },
                                '@media (hover: none)': {
                                  '&:active': {
                                    transform: 'scale(0.98)',
                                  }
                                }
                              }}
                            >
                              <Box
                                sx={{ width: '100%' }}
                              >
                                <Stack spacing={1}>
                                  <Box 
                                    display="flex" 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                    onClick={(e) => {
                                      if (category === 'To Do' && !task.assignedTo) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedTask(task);
                                        setTaskDetailsOpen(true);
                                      }
                                    }}
                                    sx={{
                                      cursor: category === 'To Do' && !task.assignedTo ? 'pointer' : 'default'
                                    }}
                                  >
                                    <Typography 
                                      variant="subtitle1" 
                                      sx={{ 
                                        fontWeight: 'bold',
                                        color: '#2196F3',
                                        fontFamily: 'sweater-school, sans-serif',
                                        fontSize: '1.1rem',
                                        flex: 1
                                      }}
                                    >
                                      {task.title}
                                    </Typography>
                                    {category === 'To Do' && (
                                      <Box display="flex" gap={1} ml={2} onClick={(e) => e.stopPropagation()}>
                                        {canEditTask(task) ? (
                                          <>
                                            <IconButton
                                              size="small"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditClick(task);
                                              }}
                                              sx={{
                                                background: 'linear-gradient(135deg, #E3F2FD 0%, #BBDEFB 100%)',
                                                padding: '8px',
                                                '&:hover': {
                                                  background: 'linear-gradient(135deg, #BBDEFB 0%, #90CAF9 100%)',
                                                  transform: 'scale(1.1)',
                                                },
                                                transition: 'all 0.2s ease'
                                              }}
                                            >
                                              <EditIcon sx={{ color: '#2196F3', fontSize: '1.2rem' }} />
                                            </IconButton>
                                            <IconButton
                                              size="small"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteTask(task.id);
                                              }}
                                              sx={{
                                                background: 'linear-gradient(135deg, #FFEBEE 0%, #FFCDD2 100%)',
                                                padding: '8px',
                                                '&:hover': {
                                                  background: 'linear-gradient(135deg, #FFCDD2 0%, #EF9A9A 100%)',
                                                  transform: 'scale(1.1)',
                                                },
                                                transition: 'all 0.2s ease'
                                              }}
                                            >
                                              <DeleteIcon sx={{ color: '#F44336', fontSize: '1.2rem' }} />
                                            </IconButton>
                                          </>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setSelectedTask(task);
                                              setTaskDetailsOpen(true);
                                            }}
                                            sx={{
                                              background: 'linear-gradient(135deg, #4CAF50 0%, #45a049 100%)',
                                              color: 'white',
                                              '&:hover': {
                                                background: 'linear-gradient(135deg, #45a049 0%, #3d8b40 100%)',
                                              },
                                            }}
                                          >
                                            Assign Me
                                          </Button>
                                        )}
                                      </Box>
                                    )}
                                  </Box>
                                  <Box display="flex" alignItems="center" gap={1}>
                                    <TimeIcon sx={{ 
                                      fontSize: 16,
                                      color: '#4CAF50' 
                                    }} />
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        color: '#4CAF50', 
                                        fontFamily: 'sweater-school, sans-serif',
                                        fontSize: '0.9rem'
                                      }}
                                    >
                                      {new Date(task.deadline).toLocaleDateString()}
                                    </Typography>
                                  </Box>
                                  <Box display="flex" alignItems="center" gap={1}>
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        color: '#3366cc',
                                        fontFamily: 'sweater-school, sans-serif',
                                        fontSize: '0.9rem',
                                        fontStyle: 'italic'
                                      }}
                                    >
                                      Created by: {users[task.createdBy]?.name || 'Unknown Teacher'}
                                    </Typography>
                                  </Box>
                                  <Box 
                                    display="flex" 
                                    alignItems="center" 
                                    gap={1} 
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (isAdmin) {
                                        setEditingPoints({ id: task.id, points: task.points });
                                      }
                                    }}
                                    sx={{
                                      background: 'linear-gradient(135deg, rgba(51, 102, 204, 0.1) 0%, rgba(51, 102, 204, 0.2) 100%)',
                                      borderRadius: '12px',
                                      padding: '4px 12px',
                                      border: '1px solid rgba(51, 102, 204, 0.3)',
                                      boxShadow: '0 2px 8px rgba(51, 102, 204, 0.15)',
                                      transition: 'all 0.3s ease',
                                      cursor: isAdmin ? 'pointer' : 'default',
                                      '&:hover': isAdmin ? {
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 4px 12px rgba(51, 102, 204, 0.25)',
                                        background: 'linear-gradient(135deg, rgba(51, 102, 204, 0.15) 0%, rgba(51, 102, 204, 0.25) 100%)',
                                      } : {},
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png"
                                      alt="Coins"
                                      sx={{
                                        width: '24px',
                                        height: '24px',
                                        objectFit: 'contain',
                                        animation: 'shine 2s infinite',
                                        filter: 'drop-shadow(0 2px 4px rgba(51, 102, 204, 0.2))',
                                        '@keyframes shine': {
                                          '0%': {
                                            filter: 'drop-shadow(0 2px 4px rgba(51, 102, 204, 0.2)) brightness(100%)',
                                          },
                                          '50%': {
                                            filter: 'drop-shadow(0 2px 4px rgba(51, 102, 204, 0.3)) brightness(120%)',
                                          },
                                          '100%': {
                                            filter: 'drop-shadow(0 2px 4px rgba(51, 102, 204, 0.2)) brightness(100%)',
                                          },
                                        },
                                      }}
                                    />
                                    {editingPoints?.id === task.id ? (
                                      <TextField
                                        autoFocus
                                        size="small"
                                        type="number"
                                        value={editingPoints.points}
                                        onClick={(e) => e.stopPropagation()}
                                        onChange={(e) => {
                                          const value = parseInt(e.target.value);
                                          if (!isNaN(value) && value >= 0) {
                                            setEditingPoints(prev => prev ? { ...prev, points: value } : null);
                                          }
                                        }}
                                        onKeyPress={(e) => {
                                          if (e.key === 'Enter' && editingPoints) {
                                            handlePointsUpdate(editingPoints.id, editingPoints.points);
                                          }
                                        }}
                                        onBlur={() => {
                                          if (editingPoints) {
                                            handlePointsUpdate(editingPoints.id, editingPoints.points);
                                          }
                                        }}
                                        InputProps={{
                                          sx: {
                                            '& input': {
                                              color: '#3366cc',
                                              fontFamily: 'sweater-school, sans-serif',
                                              fontWeight: 'bold',
                                              fontSize: '1rem',
                                              padding: '4px 8px',
                                              width: '60px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                              borderColor: 'rgba(51, 102, 204, 0.3)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                              borderColor: 'rgba(51, 102, 204, 0.5)',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                              borderColor: '#3366cc',
                                            },
                                          },
                                        }}
                                      />
                                    ) : (
                                      <Typography 
                                        variant="caption" 
                                        sx={{ 
                                          color: '#3366cc',
                                          fontFamily: 'sweater-school, sans-serif',
                                          fontWeight: 'bold',
                                          fontSize: '1rem',
                                          textShadow: '0 1px 2px rgba(51, 102, 204, 0.1)',
                                        }}
                                      >
                                        {task.points} coins
                                      </Typography>
                                    )}
                                  </Box>
                                  <Box display="flex" alignItems="center" gap={1}>
                                    <FlagIcon sx={{ 
                                      fontSize: 16,
                                      color: '#F44336' 
                                    }} />
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        color: '#F44336', 
                                        fontFamily: 'sweater-school, sans-serif',
                                        fontWeight: '500',
                                        fontSize: '0.9rem'
                                      }}
                                    >
                                      {task.priority.charAt(0).toUpperCase() + task.priority.slice(1)}
                                    </Typography>
                                  </Box>
                                  {task.checklist && (
                                    <Typography 
                                      variant="caption" 
                                      sx={{ 
                                        color: '#2196F3', 
                                        fontFamily: 'sweater-school, sans-serif',
                                        fontWeight: '500',
                                        fontSize: '0.9rem'
                                      }}
                                    >
                                      {task.checklist.filter(item => item.completed).length} of {task.checklist.length} tasks
                                    </Typography>
                                  )}
                                  {task.category === 'In Progress' && task.assignedTo === currentUser?.uid && (
                                    <Box mt={2}>
                                      <input
                                        type="file"
                                        id={`file-upload-${task.id}`}
                                        style={{ display: 'none' }}
                                        onChange={(e) => {
                                          const file = e.target.files?.[0];
                                          if (file) {
                                            handleFileUpload(task.id, file, true);
                                          }
                                        }}
                                      />
                                      <label htmlFor={`file-upload-${task.id}`}>
                                        <Button
                                          component="span"
                                          variant="outlined"
                                          startIcon={<UploadIcon />}
                                        >
                                          Upload Completed Work
                                        </Button>
                                      </label>
                                    </Box>
                                  )}
                                </Stack>
                              </Box>
                            </Card>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </DroppableWrapper>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DragDropContext>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Create New Task</DialogTitle>
        <DialogContent>
          <Stack spacing={3} mt={2}>
            <TextField
              label="Title"
              fullWidth
              value={newTask.title}
              onChange={(e) => setNewTask(prev => ({ ...prev, title: e.target.value }))}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={newTask.description}
              onChange={(e) => setNewTask(prev => ({ ...prev, description: e.target.value }))}
            />
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={newTask.priority}
                label="Priority"
                onChange={(e) => setNewTask(prev => ({ ...prev, priority: e.target.value }))}
              >
                {PRIORITIES.map(priority => (
                  <MenuItem key={priority.value} value={priority.value}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <FlagIcon sx={{ color: priority.color }} />
                      {priority.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Deadline"
                value={newTask.deadline}
                onChange={(date) => date && setNewTask(prev => ({ ...prev, deadline: date }))}
              />
            </LocalizationProvider>
            <TextField
              label="Points"
              type="number"
              fullWidth
              value={newTask.points}
              onChange={(e) => setNewTask(prev => ({ ...prev, points: Number(e.target.value) }))}
            />
            <Box>
              <Typography variant="subtitle1" mb={1}>Checklist</Typography>
              <Box display="flex" gap={1} mb={2}>
                <TextField
                  label="New item"
                  fullWidth
                  value={checklistItem}
                  onChange={(e) => setChecklistItem(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddChecklistItem()}
                />
                <Button variant="contained" onClick={handleAddChecklistItem}>Add</Button>
              </Box>
              {newTask.checklist.map((item, index) => (
                <Box key={item.id} display="flex" alignItems="center" gap={1} mb={1}>
                  <Typography>{index + 1}. {item.text}</Typography>
                </Box>
              ))}
            </Box>
            <Button
              variant="outlined"
              component="label"
              startIcon={<UploadIcon />}
            >
              Upload File
              <input
                type="file"
                hidden
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    setSelectedFile(file);
                  }
                }}
              />
            </Button>
            {selectedFile && (
              <Typography variant="caption">
                Selected file: {selectedFile.name}
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={handleCreateTask}
            variant="contained"
            disabled={uploadProgress}
          >
            {uploadProgress ? <CircularProgress size={24} /> : 'Create Task'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!!editTask} onClose={() => setEditTask(null)} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <Stack spacing={3} mt={2}>
            <TextField
              label="Title"
              fullWidth
              value={editedTask.title}
              onChange={(e) => setEditedTask(prev => ({ ...prev, title: e.target.value }))}
            />
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={editedTask.description}
              onChange={(e) => setEditedTask(prev => ({ ...prev, description: e.target.value }))}
            />
            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={editedTask.priority}
                label="Priority"
                onChange={(e) => setEditedTask(prev => ({ ...prev, priority: e.target.value }))}
              >
                {PRIORITIES.map(priority => (
                  <MenuItem key={priority.value} value={priority.value}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <FlagIcon sx={{ color: priority.color }} />
                      {priority.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Deadline"
                value={editedTask.deadline}
                onChange={(date) => date && setEditedTask(prev => ({ ...prev, deadline: date }))}
              />
            </LocalizationProvider>
            <TextField
              label="Points"
              type="number"
              fullWidth
              value={editedTask.points}
              onChange={(e) => setEditedTask(prev => ({ ...prev, points: Number(e.target.value) }))}
            />
            <Box>
              <Typography variant="subtitle1" mb={1}>Checklist</Typography>
              <Box display="flex" gap={1} mb={2}>
                <TextField
                  label="New item"
                  fullWidth
                  value={checklistItem}
                  onChange={(e) => setChecklistItem(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleAddChecklistItem()}
                />
                <Button variant="contained" onClick={handleAddChecklistItem}>Add</Button>
              </Box>
              {editedTask.checklist.map((item, index) => (
                <Box key={item.id} display="flex" alignItems="center" gap={1} mb={1}>
                  <Typography>{index + 1}. {item.text}</Typography>
                </Box>
              ))}
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle2" color="primary" gutterBottom>
                Task Attachment
              </Typography>
              {editTask?.fileUrl ? (
                <Box display="flex" alignItems="center" gap={1}>
                  <Link href={editTask.fileUrl} target="_blank" rel="noopener noreferrer">
                    {editTask.fileName}
                  </Link>
                  <IconButton 
                    size="small" 
                    onClick={() => handleFileRemoval(editTask.id)}
                    color="error"
                    disabled={uploadProgress}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <input
                    type="file"
                    id="task-file-upload"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file && editTask) {
                        handleFileUpload(editTask.id, file, false);
                      }
                    }}
                    disabled={uploadProgress}
                  />
                  <label htmlFor="task-file-upload">
                    <Button
                      component="span"
                      variant="outlined"
                      startIcon={uploadProgress ? <CircularProgress size={20} /> : <UploadIcon />}
                      size="small"
                      disabled={uploadProgress}
                    >
                      {uploadProgress ? 'Uploading...' : 'Upload Attachment'}
                    </Button>
                  </label>
                </Box>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditTask(null)}>Cancel</Button>
          <Button
            onClick={handleUpdateTask}
            variant="contained"
            disabled={uploadProgress}
          >
            {uploadProgress ? <CircularProgress size={24} /> : 'Update Task'}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Task Details Dialog */}
      <Dialog
        open={taskDetailsOpen}
        onClose={() => setTaskDetailsOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Task Details
        </DialogTitle>
        <DialogContent>
          {selectedTask && (
            <Stack spacing={2}>
              <Typography variant="h6">{selectedTask.title}</Typography>
              <Typography>{selectedTask.description}</Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <TimeIcon sx={{ color: '#4CAF50' }} />
                <Typography>
                  Deadline: {new Date(selectedTask.deadline).toLocaleDateString()}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <FlagIcon sx={{ color: '#F44336' }} />
                <Typography>
                  Priority: {selectedTask.priority}
                </Typography>
              </Box>
              {selectedTask.checklist && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Checklist:
                  </Typography>
                  <List>
                    {selectedTask.checklist.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <CheckCircleOutlineIcon sx={{ color: item.completed ? '#4CAF50' : '#9e9e9e' }} />
                        </ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
              {selectedTask.fileUrl && (
                <Box mt={2}>
                  <Typography variant="subtitle2" color="primary">
                    Task Attachment:
                  </Typography>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Link href={selectedTask.fileUrl} target="_blank" rel="noopener noreferrer">
                      {selectedTask.fileName}
                    </Link>
                    <IconButton 
                      size="small" 
                      onClick={() => window.open(selectedTask.fileUrl, '_blank')}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}
              {selectedTask.submissionUrl && (
                <Box mt={2}>
                  <Typography variant="subtitle2" color="primary">
                    Submitted Work:
                  </Typography>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Link href={selectedTask.submissionUrl} target="_blank" rel="noopener noreferrer">
                      {selectedTask.submissionFileName}
                    </Link>
                    <IconButton 
                      size="small" 
                      onClick={() => window.open(selectedTask.submissionUrl, '_blank')}
                    >
                      <DownloadIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleNotInterested(selectedTask!)} color="inherit">
            Not Interested
          </Button>
          <Button
            onClick={() => handleAssignTask(selectedTask!)}
            variant="contained"
            color="primary"
          >
            Take This Task
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Tasks;
