import React from 'react';
import { Clock } from 'lucide-react';
import { format } from 'date-fns';

interface AttendanceButtonProps {
  type: 'IN' | 'OUT';
  lastTimestamp: Date | null;
  onPress: () => void;
  disabled?: boolean;
}

export default function AttendanceButton({ 
  type, 
  lastTimestamp, 
  onPress,
  disabled = false 
}: AttendanceButtonProps) {
  return (
    <div className="flex flex-col items-center group">
      <button
        onClick={onPress}
        disabled={disabled}
        className={`attendance-btn ${
          type === 'IN' ? 'attendance-btn-in' : 'attendance-btn-out'
        } disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100`}
      >
        <Clock className="w-12 h-12 mb-4 group-hover:rotate-12 transition-transform" />
        <span className="text-2xl font-bold tracking-widest">{type}</span>
      </button>
      {lastTimestamp && (
        <div className="mt-6 px-6 py-3 bg-white rounded-xl shadow-md">
          <p className="text-sm font-medium text-gray-600">
            Last {type}: {format(lastTimestamp, 'hh:mm a')}
          </p>
        </div>
      )}
    </div>
  );
}