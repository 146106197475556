import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { format, eachDayOfInterval, subDays, subMonths, differenceInMinutes, differenceInHours, startOfMonth, endOfMonth, isSunday, isToday } from 'date-fns';
import toast from 'react-hot-toast';
import { Calendar, ChevronDown, ChevronUp, Download } from 'lucide-react';
import * as XLSX from 'xlsx';
import LeaveForm from './LeaveForm';

interface AttendanceRecord {
  date: Date;
  status: 'present' | 'leave' | 'weekend' | 'not_logged' | 'in_progress';
  punchInTime?: Date;
  punchOutTime?: Date;
  workingHours?: string;
}

interface MonthlyRecord {
  month: string;
  totalDays: number;
  presentDays: number;
  leaveDays: number;
  weekendDays: number;
  expanded: boolean;
  records: AttendanceRecord[];
}

export default function TeacherAttendance() {
  const { user } = useAuth();
  const [recentAttendance, setRecentAttendance] = useState<AttendanceRecord[]>([]);
  const [monthlyRecords, setMonthlyRecords] = useState<MonthlyRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const fetchAttendance = async () => {
      if (!user?.id) return;

      try {
        // Get date ranges
        const today = new Date();
        const thirtyDaysAgo = subDays(today, 30);
        const sixMonthsAgo = subMonths(today, 6);

        // Query all attendance records for this teacher
        const attendanceQuery = query(
          collection(db, 'attendance'),
          where('teacherId', '==', user.id)
        );

        const snapshot = await getDocs(attendanceQuery);
        
        // Create a map of attendance records
        const attendanceMap = new Map();
        
        snapshot.docs.forEach(doc => {
          const data = doc.data();
          const date = format(data.timestamp.toDate(), 'yyyy-MM-dd');
          const record = attendanceMap.get(date) || { inTime: null, outTime: null };
          
          if (data.type === 'IN') {
            record.inTime = data.timestamp.toDate();
          } else if (data.type === 'OUT') {
            record.outTime = data.timestamp.toDate();
          }
          
          attendanceMap.set(date, record);
        });

        // Process recent attendance (last 30 days)
        const recentDays = eachDayOfInterval({
          start: thirtyDaysAgo,
          end: today,
        }).reverse(); // Reverse to show latest first

        const recentRecords = recentDays.map(date => {
          const dateStr = format(date, 'yyyy-MM-dd');
          const record = attendanceMap.get(dateStr);
          
          if (isSunday(date)) {
            return {
              date,
              status: 'weekend' as const
            };
          }

          if (record?.inTime) {
            const workingHours = record.outTime 
              ? calculateWorkingHours(record.inTime, record.outTime)
              : isToday(date) 
                ? calculateWorkingHours(record.inTime, currentTime)
                : 'In Progress';

            return {
              date,
              status: record.outTime ? 'present' : 'in_progress',
              punchInTime: record.inTime,
              punchOutTime: record.outTime,
              workingHours
            };
          }

          return {
            date,
            status: isToday(date) ? 'not_logged' as const : 'leave' as const
          };
        });

        setRecentAttendance(recentRecords);

        // Process monthly records (sort months in descending order)
        const months: MonthlyRecord[] = [];
        let currentDate = sixMonthsAgo;

        while (currentDate < thirtyDaysAgo) {
          const monthStart = startOfMonth(currentDate);
          const monthEnd = endOfMonth(currentDate);
          const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd }).reverse(); // Reverse days within month
          
          const monthlyAttendance = daysInMonth.map(date => {
            const dateStr = format(date, 'yyyy-MM-dd');
            const record = attendanceMap.get(dateStr);
            
            if (isSunday(date)) {
              return {
                date,
                status: 'weekend' as const
              };
            }

            if (record?.inTime) {
              const workingHours = record.outTime 
                ? calculateWorkingHours(record.inTime, record.outTime)
                : 'In Progress';

              return {
                date,
                status: 'present' as const,
                punchInTime: record.inTime,
                punchOutTime: record.outTime,
                workingHours
              };
            }

            return {
              date,
              status: 'leave' as const
            };
          });

          const monthRecord: MonthlyRecord = {
            month: format(monthStart, 'MMMM yyyy'),
            totalDays: monthlyAttendance.length,
            presentDays: monthlyAttendance.filter(r => r.status === 'present').length,
            leaveDays: monthlyAttendance.filter(r => r.status === 'leave').length,
            weekendDays: monthlyAttendance.filter(r => r.status === 'weekend').length,
            expanded: false,
            records: monthlyAttendance
          };

          months.push(monthRecord);
          currentDate = subDays(endOfMonth(currentDate), -1);
        }

        setMonthlyRecords(months.reverse()); // Reverse months to show latest first
      } catch (error) {
        console.error('Error fetching attendance:', error);
        toast.error('Failed to load attendance data');
      } finally {
        setLoading(false);
      }
    };

    fetchAttendance();
  }, [user?.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  const calculateWorkingHours = (inTime: Date, outTime: Date) => {
    const minutes = differenceInMinutes(outTime, inTime);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const exportToExcel = () => {
    try {
      const exportData = recentAttendance
        .filter(record => record.status === 'present')
        .map(record => ({
          'Date': format(record.date, 'dd/MM/yyyy'),
          'Status': record.status,
          'Punch In': record.punchInTime ? format(record.punchInTime, 'hh:mm a') : '-',
          'Punch Out': record.punchOutTime ? format(record.punchOutTime, 'hh:mm a') : '-',
          'Working Hours': record.workingHours || '-'
        }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Attendance');

      // Auto-size columns
      const colWidths = Object.keys(exportData[0] || {}).map(key => ({
        wch: Math.max(
          key.length,
          ...exportData.map(row => String(row[key]).length)
        )
      }));
      ws['!cols'] = colWidths;

      const fileName = `attendance_${format(new Date(), 'yyyy-MM-dd')}.xlsx`;
      XLSX.writeFile(wb, fileName);
      toast.success('Excel file downloaded successfully');
    } catch (error) {
      console.error('Error exporting to Excel:', error);
      toast.error('Failed to export data');
    }
  };

  const toggleMonthExpand = (index: number) => {
    setMonthlyRecords(prevRecords => 
      prevRecords.map((record, i) => 
        i === index ? { ...record, expanded: !record.expanded } : record
      )
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Leave Form */}
      <LeaveForm />

      {/* Recent Attendance (30 days) */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-secondary flex items-center">
            <Calendar className="w-6 h-6 mr-2 text-primary" />
            Recent Attendance (Last 30 Days)
          </h2>
          <button
            onClick={exportToExcel}
            className="btn-primary flex items-center gap-2"
            disabled={recentAttendance.length === 0}
          >
            <Download className="w-5 h-5" />
            Export to Excel
          </button>
        </div>

        <div className="grid grid-cols-1 gap-4">
          {recentAttendance.map((record, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg flex items-center justify-between ${
                record.status === 'present'
                  ? 'bg-green-100'
                  : record.status === 'weekend'
                  ? 'bg-gray-100'
                  : record.status === 'not_logged'
                  ? 'bg-blue-100'
                  : record.status === 'in_progress'
                  ? 'bg-purple-100'
                  : 'bg-red-100'
              }`}
            >
              <div>
                <div className="font-semibold">
                  {format(record.date, 'EEEE, MMMM d, yyyy')}
                </div>
                <div className="text-sm text-gray-600">
                  {record.status === 'present' ? (
                    <>
                      <div>Punch in: {format(record.punchInTime!, 'hh:mm a')}</div>
                      {record.punchOutTime && (
                        <div>
                          Punch out: {format(record.punchOutTime, 'hh:mm a')}
                          <div className="font-medium text-primary">
                            Working Hours: {record.workingHours}
                          </div>
                        </div>
                      )}
                    </>
                  ) : record.status === 'in_progress' ? (
                    <>
                      <div>Punch in: {format(record.punchInTime!, 'hh:mm a')}</div>
                      <div className="font-medium text-purple-600">
                        Current Duration: {record.workingHours}
                      </div>
                    </>
                  ) : record.status === 'weekend' ? (
                    'Weekend'
                  ) : record.status === 'not_logged' ? (
                    'Not Logged in yet'
                  ) : (
                    'Leave'
                  )}
                </div>
              </div>
              <div
                className={`px-3 py-1 rounded-full text-sm ${
                  record.status === 'present'
                    ? 'bg-green-200 text-green-800'
                    : record.status === 'weekend'
                    ? 'bg-gray-200 text-gray-800'
                    : record.status === 'not_logged'
                    ? 'bg-blue-200 text-blue-800'
                    : record.status === 'in_progress'
                    ? 'bg-purple-200 text-purple-800'
                    : 'bg-red-200 text-red-800'
                }`}
              >
                {record.status === 'not_logged' 
                  ? 'Not Logged'
                  : record.status === 'in_progress'
                  ? 'In Progress'
                  : record.status.charAt(0).toUpperCase() + record.status.slice(1)}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Monthly Records */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-secondary mb-6">Monthly History</h2>
        <div className="space-y-4">
          {monthlyRecords.map((month, index) => (
            <div key={index} className="border rounded-lg">
              <button
                onClick={() => toggleMonthExpand(index)}
                className="w-full p-4 flex items-center justify-between hover:bg-gray-50"
              >
                <div className="flex-1">
                  <h3 className="text-lg font-semibold">{month.month}</h3>
                  <div className="grid grid-cols-3 gap-4 mt-2 text-sm text-gray-600">
                    <div>Present: {month.presentDays} days</div>
                    <div>Leave: {month.leaveDays} days</div>
                    <div>Weekend: {month.weekendDays} days</div>
                  </div>
                </div>
                {month.expanded ? (
                  <ChevronUp className="w-5 h-5 text-gray-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-500" />
                )}
              </button>
              
              {month.expanded && (
                <div className="border-t p-4 space-y-3">
                  {month.records.map((record, recordIndex) => (
                    <div
                      key={recordIndex}
                      className={`p-3 rounded-lg flex items-center justify-between ${
                        record.status === 'present'
                          ? 'bg-green-50'
                          : record.status === 'weekend'
                          ? 'bg-gray-50'
                          : 'bg-red-50'
                      }`}
                    >
                      <div>
                        <div className="font-medium">
                          {format(record.date, 'EEEE, MMMM d')}
                        </div>
                        {record.status === 'present' && record.punchInTime && (
                          <div className="text-sm text-gray-600">
                            Punch in: {format(record.punchInTime, 'hh:mm a')}
                            {record.punchOutTime && (
                              <div>
                                Punch out: {format(record.punchOutTime, 'hh:mm a')}
                                <div className="font-medium text-primary">
                                  Working Hours: {record.workingHours}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div
                        className={`px-2 py-1 rounded text-sm ${
                          record.status === 'present'
                            ? 'bg-green-100 text-green-800'
                            : record.status === 'weekend'
                            ? 'bg-gray-100 text-gray-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
