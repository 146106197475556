import { collection, query, where, getDocs, addDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import React from 'react';
import { db } from '../lib/firebase';
import { format } from 'date-fns';
import { TeacherPoints } from '../types';
import toast from 'react-hot-toast';
import { POINTS } from '../constants/points';

export function useTeacherPoints(teacherId: string, teacherName: string) {
  const [currentPoints, setCurrentPoints] = useState<TeacherPoints | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (teacherId) {
      loadCurrentPoints();
    }
  }, [teacherId]);

  const loadCurrentPoints = async () => {
    if (!teacherId) return;

    try {
      setLoading(true);
      const currentMonth = format(new Date(), 'yyyy-MM');
      
      // Create a compound query
      const pointsRef = collection(db, 'teacherPoints');
      const pointsQuery = query(
        pointsRef,
        where('teacherId', '==', teacherId),
        where('month', '==', currentMonth)
      );

      const snapshot = await getDocs(pointsQuery);
      
      if (!snapshot.empty) {
        const data = snapshot.docs[0].data();
        setCurrentPoints({
          id: snapshot.docs[0].id,
          ...data,
          createdAt: data.createdAt.toDate(),
          updatedAt: data.updatedAt.toDate()
        } as TeacherPoints);
      } else {
        // Initialize points for the current month
        const initialPoints = {
          teacherId,
          teacherName,
          month: currentMonth,
          dailyUpdatePoints: 0,
          kSheetPoints: 0,
          totalPoints: 0,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now()
        };

        const docRef = await addDoc(pointsRef, initialPoints);
        setCurrentPoints({
          id: docRef.id,
          ...initialPoints,
          createdAt: initialPoints.createdAt.toDate(),
          updatedAt: initialPoints.updatedAt.toDate()
        });
      }
    } catch (error) {
      console.error('Error loading points:', error);
      // Don't show error toast here as it's not critical for the user experience
    } finally {
      setLoading(false);
    }
  };

  const addPoints = async (type: 'dailyUpdate' | 'kSheet') => {
    if (!teacherId || !teacherName) return;

    try {
      const currentMonth = format(new Date(), 'yyyy-MM');
      const now = Timestamp.now();
      
      if (!currentPoints) {
        // Create new points record for the month
        const newPoints = {
          teacherId,
          teacherName,
          month: currentMonth,
          dailyUpdatePoints: type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : 0,
          kSheetPoints: type === 'kSheet' ? POINTS.K_SHEET : 0,
          totalPoints: type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : POINTS.K_SHEET,
          createdAt: now,
          updatedAt: now
        };

        const docRef = await addDoc(collection(db, 'teacherPoints'), newPoints);
        setCurrentPoints({ 
          id: docRef.id, 
          ...newPoints, 
          createdAt: now.toDate(),
          updatedAt: now.toDate()
        });
      } else {
        // Update existing points
        const pointsToAdd = type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : POINTS.K_SHEET;
        const updates = {
          dailyUpdatePoints: type === 'dailyUpdate' 
            ? currentPoints.dailyUpdatePoints + POINTS.DAILY_UPDATE 
            : currentPoints.dailyUpdatePoints,
          kSheetPoints: type === 'kSheet'
            ? currentPoints.kSheetPoints + POINTS.K_SHEET
            : currentPoints.kSheetPoints,
          totalPoints: currentPoints.totalPoints + pointsToAdd,
          updatedAt: now
        };

        await updateDoc(doc(db, 'teacherPoints', currentPoints.id), updates);
        setCurrentPoints({
          ...currentPoints,
          ...updates,
          updatedAt: now.toDate()
        });
      }

      toast.success(
        () => (
          <React.Fragment>
            <div className="flex items-center gap-2">
              <span>Earned {type === 'dailyUpdate' ? POINTS.DAILY_UPDATE : POINTS.K_SHEET} points!</span>
              <img 
                src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
                alt="Points Coin"
                className="w-5 h-5 object-contain"
              />
            </div>
          </React.Fragment>
        )
      );
    } catch (error) {
      console.error('Error updating points:', error);
      toast.error('Failed to update points');
    }
  };

  return {
    currentPoints,
    loading,
    addPoints
  };
}