import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Student } from '../types';
import Select from 'react-select';

interface StudentSelectProps {
  onSelect: (student: Student) => void;
}

export default function StudentSelect({ onSelect }: StudentSelectProps) {
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadStudents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'students'));
        const loadedStudents = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Student));
        setStudents(loadedStudents);
      } catch (error) {
        console.error('Error loading students:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadStudents();
  }, []);

  const options = students.map(student => ({
    value: student,
    label: `${student.name} - ${student.school} (${student.grade}${student.division})`
  }));

  return (
    <Select
      className="react-select-container"
      classNamePrefix="react-select"
      options={options}
      isLoading={isLoading}
      onChange={(option) => option && onSelect(option.value)}
      placeholder="Select or search student..."
      isClearable
    />
  );
}