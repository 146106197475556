import React from 'react';
import { Droppable, DroppableProvided } from '@hello-pangea/dnd';

type DroppableWrapperProps = {
  droppableId: string;
  children: (provided: DroppableProvided) => React.ReactNode;
  type?: 'DEFAULT' | string;
  direction?: 'vertical' | 'horizontal';
  ignoreContainerClipping?: boolean;
  isCombineEnabled?: boolean;
  isDropDisabled?: boolean;
  mode?: 'standard' | 'virtual';
};

// Using function declaration instead of arrow function to avoid memo/defaultProps issues
function DroppableWrapper(props: DroppableWrapperProps) {
  const {
    droppableId,
    children,
    type = 'DEFAULT',
    direction = 'vertical',
    ignoreContainerClipping = false,
    isCombineEnabled = false,
    isDropDisabled = false,
    mode = 'standard'
  } = props;

  return (
    <Droppable
      droppableId={droppableId}
      type={type}
      direction={direction}
      ignoreContainerClipping={ignoreContainerClipping}
      isCombineEnabled={isCombineEnabled}
      isDropDisabled={isDropDisabled}
      mode={mode}
    >
      {(provided) => children(provided)}
    </Droppable>
  );
}

export default DroppableWrapper;
