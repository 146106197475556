import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import Sidebar from './components/Sidebar';
import AttendanceButton from './components/AttendanceButton';
import DailyUpdateForm from './components/DailyUpdateForm';
import StudentForm from './components/StudentForm';
import AdminView from './components/AdminView';
import LoginForm from './components/LoginForm';
import TeacherActivity from './components/TeacherActivity';
import TeacherAttendance from './components/TeacherAttendance';
import SettingsView from './components/SettingsView';
import StudentPerformance from './components/StudentPerformance';
import Tasks from './components/Tasks';
import { useAttendance } from './hooks/useAttendance';
import { useAuth } from './hooks/useAuth';
import { db } from './lib/firebase';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import { subDays, differenceInMinutes, format } from 'date-fns';
import toast from 'react-hot-toast';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

let capacitorApp;
let statusBar;
let haptics;

// Only import Capacitor modules in web environment
if (typeof window !== 'undefined') {
  Promise.all([
    import('@capacitor/app'),
    import('@capacitor/status-bar'),
    import('@capacitor/haptics')
  ]).then(([app, sb, h]) => {
    capacitorApp = app.App;
    statusBar = sb.StatusBar;
    haptics = h.Haptics;
  }).catch(error => {
    console.log('Capacitor modules not available:', error);
  });
}

export default function App() {
  const [currentView, setCurrentView] = useState('dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user, loading, signOut } = useAuth();
  const { lastInTime, lastOutTime, handleAttendance } = useAttendance(user?.id || '');
  const [teacherStats, setTeacherStats] = useState({
    averageWorkingHours: '0h 0m',
    averageKSheets: 0,
    points: 0,
    completionRate: 85
  });

  const POINTS = {
    DAILY_UPDATE: 30,
    K_SHEET: 100
  };

  const fetchPoints = async (teacherId: string) => {
    try {
      const thirtyDaysAgo = subDays(new Date(), 30);
      const dailyUpdatesRef = collection(db, 'dailyUpdates');
      const q = query(
        dailyUpdatesRef,
        where('teacherId', '==', teacherId),
      );

      const querySnapshot = await getDocs(q);
      const updates = querySnapshot.docs
        .map(doc => ({
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
          id: doc.id
        }))
        .filter(update => update.createdAt >= thirtyDaysAgo);

      return updates;
    } catch (error) {
      console.error('Error fetching points:', error);
      toast.error('Failed to fetch activity data');
      throw error;
    }
  };

  useEffect(() => {
    const setupCapacitor = async () => {
      if (Capacitor.isNativePlatform()) {
        try {
          await statusBar.setBackgroundColor({ color: '#FFFFFF' });
        } catch (error) {
          console.warn('StatusBar setup failed:', error);
        }
      }

      if (capacitorApp) {
        capacitorApp.addListener('backButton', ({ canGoBack }) => {
          if (!canGoBack) {
            capacitorApp.exitApp();
          } else {
            window.history.back();
          }
        });
      }
    };

    setupCapacitor();
  }, []);

  useEffect(() => {
    const fetchTeacherStats = async () => {
      if (!user?.id) return;

      try {
        // Get attendance records
        const attendanceRef = collection(db, 'attendance');
        const attendanceQuery = query(
          attendanceRef,
          where('teacherId', '==', user.id)
        );
        const attendanceSnapshot = await getDocs(attendanceQuery);
        
        // Create a map to store daily attendance records
        const dailyAttendance = new Map();
        
        // Filter records within last 30 days in memory
        const thirtyDaysAgo = subDays(new Date(), 30).getTime();
        
        attendanceSnapshot.docs.forEach(doc => {
          const data = doc.data();
          const timestamp = data.timestamp.toDate();
          
          // Only process records from last 30 days
          if (timestamp.getTime() >= thirtyDaysAgo) {
            const date = format(timestamp, 'yyyy-MM-dd');
            const record = dailyAttendance.get(date) || { inTime: null, outTime: null };
            
            if (data.type === 'IN') {
              record.inTime = timestamp;
            } else if (data.type === 'OUT') {
              record.outTime = timestamp;
            }
            
            dailyAttendance.set(date, record);
          }
        });

        // Calculate working hours for completed days
        let totalMinutes = 0;
        let workingDays = 0;

        dailyAttendance.forEach((record) => {
          if (record.inTime && record.outTime) {
            totalMinutes += differenceInMinutes(record.outTime, record.inTime);
            workingDays++;
          }
        });

        // Calculate average
        const avgMinutes = workingDays > 0 ? totalMinutes / workingDays : 0;
        const avgHours = Math.floor(avgMinutes / 60);
        const remainingMinutes = Math.round(avgMinutes % 60);

        // Get daily updates using the same logic as TeacherActivity
        const updates = await fetchPoints(user.id);
        
        // Calculate K-sheets and points for last 7 days
        let kSheetCount = 0;
        let totalPoints = 0;

        // Filter updates for last 7 days
        updates
          .filter(update => update.createdAt >= subDays(new Date(), 7))
          .forEach(update => {
            totalPoints += POINTS.DAILY_UPDATE;
            if (update.kSheetGiven === 'yes') {
              kSheetCount++;
              totalPoints += POINTS.K_SHEET;
            }
          });

        const avgKSheets = kSheetCount / 7;

        // Get current month's points from teacherPoints collection
        const currentMonth = format(new Date(), 'yyyy-MM');
        const pointsQuery = query(
          collection(db, 'teacherPoints'),
          where('teacherId', '==', user.id),
          where('month', '==', currentMonth)
        );
        const pointsSnapshot = await getDocs(pointsQuery);
        const monthPoints = pointsSnapshot.docs[0]?.data()?.totalPoints || 0;

        setTeacherStats({
          averageWorkingHours: workingDays > 0 
            ? `${avgHours}h ${remainingMinutes}m`
            : '0h 0m',
          averageKSheets: avgKSheets,
          points: monthPoints,
          completionRate: 85
        });

      } catch (error) {
        console.error('Error fetching teacher stats:', error);
        toast.error('Failed to load teacher statistics');
      }
    };

    if (user?.id) {
      fetchTeacherStats();
      // Refresh stats every 5 minutes
      const interval = setInterval(fetchTeacherStats, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [user?.id]);

  const handleViewChange = async (view: string) => {
    try {
      if (haptics) {
        await haptics.impact({ style: 'light' });
      }
    } catch (error) {
      console.log('Haptics not available:', error);
    }
    setCurrentView(view);
    setIsSidebarOpen(false);
  };

  function LiveCounter({ inTime }: { inTime: Date | null }) {
    const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
      if (!inTime) return;

      const updateCounter = () => {
        const now = new Date();
        const diff = now.getTime() - inTime.getTime();
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        setTime({ hours, minutes, seconds });
      };

      updateCounter();
      const interval = setInterval(updateCounter, 1000);
      return () => clearInterval(interval);
    }, [inTime]);

    if (!inTime) return null;

    return (
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-gray-800 mb-4">Today's Working Hours</h3>
        <div className="flex justify-center gap-4">
          <div className="counter-box">
            <div className="relative w-24 h-24 flex items-center justify-center bg-gradient-to-br from-primary/20 to-primary/10 rounded-2xl shadow-inner">
              <div className="absolute inset-0.5 bg-white rounded-2xl"></div>
              <div className="relative">
                <span className="text-4xl font-bold text-primary">{time.hours.toString().padStart(2, '0')}</span>
                <span className="block text-xs text-gray-500 text-center mt-1">Hours</span>
              </div>
            </div>
          </div>
          <div className="counter-box">
            <div className="relative w-24 h-24 flex items-center justify-center bg-gradient-to-br from-secondary/20 to-secondary/10 rounded-2xl shadow-inner">
              <div className="absolute inset-0.5 bg-white rounded-2xl"></div>
              <div className="relative">
                <span className="text-4xl font-bold text-secondary">{time.minutes.toString().padStart(2, '0')}</span>
                <span className="block text-xs text-gray-500 text-center mt-1">Minutes</span>
              </div>
            </div>
          </div>
          <div className="counter-box">
            <div className="relative w-24 h-24 flex items-center justify-center bg-gradient-to-br from-accent/20 to-accent/10 rounded-2xl shadow-inner">
              <div className="absolute inset-0.5 bg-white rounded-2xl"></div>
              <div className="relative">
                <span className="text-4xl font-bold text-accent">{time.seconds.toString().padStart(2, '0')}</span>
                <span className="block text-xs text-gray-500 text-center mt-1">Seconds</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
      </div>
    );
  }

  if (!user) {
    return <LoginForm />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="fixed top-0 left-0 right-0 h-16 bg-white shadow-sm z-50 flex items-center px-4">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="p-2 hover:bg-gray-100 rounded-lg"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <img 
          src="https://static.wixstatic.com/shapes/abaee8_dc6d6d64fba440848d2b9769e4f2e998.svg" 
          alt="Inkstall Logo" 
          className="h-8 w-auto ml-4"
        />
      </header>

      <div
        className={`fixed inset-0 bg-black/50 z-40 transition-opacity duration-300 ${
          isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsSidebarOpen(false)}
      />
      
      <div
        className={`fixed top-0 left-0 bottom-0 w-64 bg-white z-50 transform transition-transform duration-300 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <Sidebar
          currentView={currentView}
          onViewChange={handleViewChange}
          userRole={user.role}
          onSignOut={signOut}
        />
      </div>

      <div className="pt-20 px-4 pb-4 md:ml-64">
        {currentView === 'dashboard' && (
          <div className="space-y-4">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-bold text-secondary mb-6">Today's Attendance</h2>
              <div className="flex flex-col sm:flex-row justify-around gap-4">
                <AttendanceButton
                  type="IN"
                  lastTimestamp={lastInTime}
                  onPress={() => handleAttendance('IN')}
                />
                <AttendanceButton
                  type="OUT"
                  lastTimestamp={lastOutTime}
                  onPress={() => handleAttendance('OUT')}
                />
              </div>
            </div>

            {lastInTime && !lastOutTime && (
              <LiveCounter inTime={lastInTime} />
            )}

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-xl font-bold text-secondary mb-6">Quick Stats</h2>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div className="bg-accent/10 p-4 rounded-lg">
                  <p className="text-accent text-2xl font-bold">{teacherStats.averageWorkingHours}</p>
                  <p className="text-gray-600">Avg. Working Hours</p>
                </div>
                <div className="bg-primary/10 p-4 rounded-lg">
                  <p className="text-secondary text-2xl font-bold">{teacherStats.averageKSheets.toFixed(1)}</p>
                  <p className="text-gray-600">Avg. K-Sheets/Day</p>
                </div>
                <div className="bg-green-100 p-4 rounded-lg">
                  <div className="flex items-center gap-2">
                    <p className="text-green-600 text-2xl font-bold">{teacherStats.points}</p>
                    <img 
                      src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
                      alt="Points" 
                      className="w-8 h-8 object-contain" 
                    />
                  </div>
                  <p className="text-gray-600">Total Points</p>
                </div>
                <div className="bg-blue-100 p-4 rounded-lg">
                  <p className="text-blue-600 text-2xl font-bold">{teacherStats.completionRate}%</p>
                  <p className="text-gray-600">Completion Rate</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentView === 'attendance' && <TeacherAttendance />}
        {currentView === 'updates' && <DailyUpdateForm />}
        {currentView === 'students' && user.role === 'admin' && <StudentForm />}
        {currentView === 'activity' && <TeacherActivity />}
        {currentView === 'tasks' && <Tasks />}
        {currentView === 'performance' && <StudentPerformance />}
        {currentView === 'admin' && user.role === 'admin' && <AdminView />}
        {currentView === 'settings' && user.role === 'admin' && <SettingsView />}

        <Toaster position="top-center" />
      </div>
    </div>
  );
}