import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { format } from 'date-fns';
import toast from 'react-hot-toast';
import Select from 'react-select';

interface Student {
  id: string;
  name: string;
  subjects: string[];
}

interface Score {
  marks: number;
  totalMarks: number;
  description: string;
}

interface PerformanceEntry {
  studentId: string;
  studentName: string;
  teacherId: string;
  subject: string;
  scores: Score[];
  averagePercentage: number;
  createdAt: Date;
}

const POINTS_FOR_PERFORMANCE_ENTRY = 200;

export default function StudentPerformance() {
  const { user } = useAuth();
  const [students, setStudents] = useState<Student[]>([]);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);
  const [selectedSubject, setSelectedSubject] = useState<string>('');
  const [scores, setScores] = useState<Score[]>(
    Array(10).fill({ marks: 0, totalMarks: 100, description: '' })
  );
  const [loading, setLoading] = useState(true);

  // Fetch students from database
  useEffect(() => {
    const fetchStudents = async () => {
      if (!user?.id) return;

      try {
        const studentsRef = collection(db, 'students');
        const querySnapshot = await getDocs(studentsRef);
        const studentsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Student));
        setStudents(studentsList);
      } catch (error) {
        console.error('Error fetching students:', error);
        toast.error('Failed to fetch students');
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [user?.id]);

  const handleScoreChange = (index: number, field: keyof Score, value: string | number) => {
    const newScores = [...scores];
    newScores[index] = {
      ...newScores[index],
      [field]: field === 'description' ? value : Number(value)
    };
    setScores(newScores);
  };

  const calculateAveragePercentage = () => {
    const validScores = scores.filter(score => score.totalMarks > 0);
    if (validScores.length === 0) return 0;

    const totalPercentage = validScores.reduce((sum, score) => 
      sum + (score.marks / score.totalMarks) * 100, 0);
    return totalPercentage / validScores.length;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedStudent || !selectedSubject || !user?.id) {
      toast.error('Please select a student and subject');
      return;
    }

    try {
      // Save performance entry
      const performanceEntry: PerformanceEntry = {
        studentId: selectedStudent.id,
        studentName: selectedStudent.name,
        teacherId: user.id,
        subject: selectedSubject,
        scores: scores,
        averagePercentage: calculateAveragePercentage(),
        createdAt: new Date()
      };

      await addDoc(collection(db, 'studentPerformance'), performanceEntry);

      // Award points to teacher
      const pointsEntry = {
        teacherId: user.id,
        points: POINTS_FOR_PERFORMANCE_ENTRY,
        type: 'PERFORMANCE_ENTRY',
        createdAt: Timestamp.now()
      };

      await addDoc(collection(db, 'teacherPoints'), pointsEntry);

      toast.success('Performance data saved successfully!');
      
      // Reset form
      setSelectedStudent(null);
      setSelectedSubject('');
      setScores(Array(10).fill({ marks: 0, totalMarks: 100, description: '' }));
    } catch (error) {
      console.error('Error saving performance:', error);
      toast.error('Failed to save performance data');
    }
  };

  if (loading) {
    return <div className="flex items-center justify-center min-h-[400px]">Loading...</div>;
  }

  const calculatePercentage = (score: Score) => {
    if (!score.totalMarks) return '0.0';
    return ((score.marks / score.totalMarks) * 100).toFixed(1);
  };

  return (
    <div className="space-y-6 p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-secondary mb-6">Student Performance</h2>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Student Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Student
              </label>
              <Select
                options={students.map(student => ({ value: student, label: student.name }))}
                value={selectedStudent ? { value: selectedStudent, label: selectedStudent.name } : null}
                onChange={(option) => setSelectedStudent(option?.value || null)}
                className="basic-select"
                classNamePrefix="select"
              />
            </div>

            {/* Subject Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Select Subject
              </label>
              <Select
                options={selectedStudent?.subjects.map(subject => ({ value: subject, label: subject })) || []}
                value={selectedSubject ? { value: selectedSubject, label: selectedSubject } : null}
                onChange={(option) => setSelectedSubject(option?.value || '')}
                className="basic-select"
                classNamePrefix="select"
                isDisabled={!selectedStudent}
              />
            </div>
          </div>

          {/* Score Entries */}
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-gray-900">Performance Scores</h3>
            <div className="grid grid-cols-1 gap-4">
              {scores.map((score, index) => (
                <div key={index} className="grid grid-cols-1 md:grid-cols-4 gap-4 items-center">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Description
                    </label>
                    <input
                      type="text"
                      value={score.description}
                      onChange={(e) => handleScoreChange(index, 'description', e.target.value)}
                      className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="e.g., Quiz 1, Homework 2"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Marks Gained
                    </label>
                    <input
                      type="number"
                      value={score.marks}
                      onChange={(e) => handleScoreChange(index, 'marks', e.target.value)}
                      className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      min="0"
                      max={score.totalMarks}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Total Marks
                    </label>
                    <input
                      type="number"
                      value={score.totalMarks}
                      onChange={(e) => handleScoreChange(index, 'totalMarks', e.target.value)}
                      className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                      min="1"
                    />
                  </div>
                  <div className="flex items-end">
                    <div className="text-sm text-gray-500">
                      Percentage: {calculatePercentage(score)}%
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Average Performance */}
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="text-lg font-medium text-gray-900">
              Average Performance: {calculateAveragePercentage().toFixed(1)}%
            </div>
            <p className="text-sm text-gray-500 mt-1">
              Submitting this form will award you {POINTS_FOR_PERFORMANCE_ENTRY} points
            </p>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Save Performance Data
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
