import { initializeApp, getApp, FirebaseApp } from 'firebase/app';
import { 
  getFirestore,
  connectFirestoreEmulator
} from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getPerformance } from 'firebase/performance';

const firebaseConfig = {
  apiKey: "AIzaSyCwbbwDTUIw0NeHpaeOKL2fMn2JsiEX-f4",
  authDomain: "questo-59b77.firebaseapp.com",
  projectId: "questo-59b77",
  storageBucket: "questo-59b77.appspot.com",
  messagingSenderId: "104723841839",
  appId: "1:104723841839:web:de544b70f6619d185857da",
  measurementId: "G-VPJRRTR992"
};

// Initialize Firebase
let app: FirebaseApp;
try {
  app = getApp();
} catch {
  app = initializeApp(firebaseConfig);
}

// Initialize Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Only connect to emulators if explicitly enabled
const useEmulators = false;

if (useEmulators && process.env.NODE_ENV === 'development') {
  console.log('Using Firebase Emulators');
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
} else {
  console.log('Using Production Firebase');
}

// Initialize performance monitoring
if (typeof window !== 'undefined') {
  const perf = getPerformance(app);
  perf.instrumentationEnabled = true;
  perf.dataCollectionEnabled = true;
}

// Debug Firebase initialization
console.log('Firebase initialized successfully with config:', {
  projectId: firebaseConfig.projectId,
  storageBucket: firebaseConfig.storageBucket,
  authDomain: firebaseConfig.authDomain,
  environment: process.env.NODE_ENV,
  origin: typeof window !== 'undefined' ? window.location.origin : 'unknown'
});