import React from 'react';
import { LayoutDashboard, ClipboardList, Users, Settings, LogOut, ShieldCheck, Trophy, Calendar, GraduationCap, CheckSquare } from 'lucide-react';

interface SidebarProps {
  currentView: string;
  onViewChange: (view: string) => void;
  userRole: 'teacher' | 'admin';
  onSignOut: () => void;
}

export default function Sidebar({ currentView, onViewChange, userRole, onSignOut }: SidebarProps) {
  const menuItems = [
    { id: 'dashboard', label: 'Today Attendance', icon: LayoutDashboard },
    { id: 'attendance', label: 'My Attendance', icon: Calendar },
    { id: 'updates', label: 'Daily Updates', icon: ClipboardList },
    { id: 'tasks', label: 'Tasks', icon: CheckSquare },
    { id: 'students', label: 'Students', icon: Users, adminOnly: true },
    { id: 'activity', label: (
      <div className="flex items-center gap-2">
        <span>Activity Points</span>
        <img 
          src="https://static.wixstatic.com/media/abaee8_d543e28c553d4b91a517d1781404fc35~mv2.png" 
          alt="Points Coin"
          className="w-5 h-5 object-contain"
        />
      </div>
    ), icon: Trophy },
    { id: 'performance', label: 'Student Performance', icon: GraduationCap },
    { id: 'admin', label: 'Admin', icon: ShieldCheck, adminOnly: true },
    { id: 'settings', label: 'Settings', icon: Settings, adminOnly: true },
  ];

  return (
    <div className="bg-white h-screen w-64 fixed left-0 top-0 shadow-lg">
      <div className="p-6">
        <img 
          src="https://static.wixstatic.com/shapes/abaee8_dc6d6d64fba440848d2b9769e4f2e998.svg" 
          alt="Inkstall Logo" 
          className="w-32 h-auto mb-4"
        />
        <p className="text-sm text-gray-600 mt-1 capitalize">{userRole} Dashboard</p>
      </div>
      
      <nav className="mt-6">
        {menuItems
          .filter(item => 
            (!item.adminOnly || userRole === 'admin') && 
            (!item.teacherOnly || userRole === 'teacher')
          )
          .map((item) => {
            const Icon = item.icon;
            return (
              <button
                key={item.id}
                onClick={() => onViewChange(item.id)}
                className={`w-full flex items-center px-6 py-3 text-gray-700 hover:bg-primary/10 transition-colors ${
                  currentView === item.id ? 'bg-primary/20 border-r-4 border-primary' : ''
                }`}
              >
                <Icon className="w-5 h-5 mr-3" />
                <span>{item.label}</span>
              </button>
            );
          })}
      </nav>

      <button 
        onClick={onSignOut}
        className="absolute bottom-8 w-full flex items-center px-6 py-3 text-gray-700 hover:bg-red-50 transition-colors"
      >
        <LogOut className="w-5 h-5 mr-3" />
        <span>Logout</span>
      </button>
    </div>
  );
}