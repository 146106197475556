import React, { useState } from 'react';
import { Settings, UserPlus, Clock } from 'lucide-react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import toast from 'react-hot-toast';

interface WorkHours {
  start: string;
  end: string;
}

interface TeacherForm {
  email: string;
  password: string;
  name: string;
  workHours: {
    monday: WorkHours;
    tuesday: WorkHours;
    wednesday: WorkHours;
    thursday: WorkHours;
    friday: WorkHours;
    saturday: WorkHours;
    sunday: WorkHours;
  };
}

const initialWorkHours: WorkHours = { start: '09:00', end: '17:00' };

const initialFormData: TeacherForm = {
  email: '',
  password: '',
  name: '',
  workHours: {
    monday: { ...initialWorkHours },
    tuesday: { ...initialWorkHours },
    wednesday: { ...initialWorkHours },
    thursday: { ...initialWorkHours },
    friday: { ...initialWorkHours },
    saturday: { ...initialWorkHours },
    sunday: { ...initialWorkHours }
  }
};

export default function SettingsView() {
  const { user } = useAuth();
  const [formData, setFormData] = useState<TeacherForm>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleWorkHoursChange = (day: keyof TeacherForm['workHours'], type: 'start' | 'end', value: string) => {
    setFormData(prev => ({
      ...prev,
      workHours: {
        ...prev.workHours,
        [day]: {
          ...prev.workHours[day],
          [type]: value
        }
      }
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);

      // Create Firebase auth user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Add user data to Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), {
        email: formData.email,
        name: formData.name,
        role: 'teacher',
        workHours: formData.workHours,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      toast.success('Teacher added successfully');
      setFormData(initialFormData);
    } catch (error: any) {
      console.error('Error adding teacher:', error);
      toast.error(error.message || 'Failed to add teacher');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!user || user.role !== 'admin') {
    return (
      <div className="p-6">
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-secondary mb-4 flex items-center">
            <Settings className="w-6 h-6 mr-2" />
            Settings
          </h2>
          <p className="text-gray-500 mb-2">Welcome to your settings page!</p>
          <p className="text-gray-500">To add new teachers to the system, please contact your administrator.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold text-secondary flex items-center">
            <UserPlus className="w-6 h-6 mr-2" />
            Add New Teacher
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Teacher Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="input-field"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="input-field"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Password
              </label>
              <input
                type="password"
                value={formData.password}
                onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                className="input-field"
                required
                minLength={6}
              />
            </div>
          </div>

          <div className="border-t pt-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
              <Clock className="w-5 h-5 mr-2 text-accent" />
              Work Hours Schedule
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {Object.entries(formData.workHours).map(([day, hours]) => (
                <div key={day} className="border rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-700 mb-3 capitalize">
                    {day}
                  </h4>
                  <div className="space-y-3">
                    <div>
                      <label className="block text-xs text-gray-500 mb-1">Start Time</label>
                      <input
                        type="time"
                        value={hours.start}
                        onChange={(e) => handleWorkHoursChange(day as keyof TeacherForm['workHours'], 'start', e.target.value)}
                        className="input-field"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-gray-500 mb-1">End Time</label>
                      <input
                        type="time"
                        value={hours.end}
                        onChange={(e) => handleWorkHoursChange(day as keyof TeacherForm['workHours'], 'end', e.target.value)}
                        className="input-field"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full btn-primary flex items-center justify-center"
          >
            <UserPlus className="w-5 h-5 mr-2" />
            {isSubmitting ? 'Adding Teacher...' : 'Add Teacher'}
          </button>
        </form>
      </div>
    </div>
  );
}