import React, { useState } from 'react';
import { CheckCircle, Plus, X } from 'lucide-react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { DailyUpdate, Board, KSheetStatus, Subject, Student } from '../types';
import StudentSelect from './StudentSelect';
import { useAuth } from '../hooks/useAuth';
import { useTeacherPoints } from '../hooks/useTeacherPoints';
import toast from 'react-hot-toast';
import { format, subDays } from 'date-fns';

const SUBJECTS: Subject[] = [
  'Physics', 'Chemistry', 'Biology', 'Mathematics', 'English',
  'Computer Science', 'Economics', 'Business Studies', 'Accounts'
];

interface StudentData {
  studentName: string;
  grade: string;
  board: Board;
}

const initialStudentData: StudentData = {
  studentName: '',
  grade: '',
  board: 'IGCSE'
};

const initialFormData = {
  subject: '' as Subject,
  chapterName: '',
  notes: '',
  chapterCompletion: 25 as const,
  date: format(new Date(), 'yyyy-MM-dd')
};

export default function DailyUpdateForm() {
  const { user } = useAuth();
  const { addPoints } = useTeacherPoints(user?.id || '', user?.name || '');
  const [formData, setFormData] = useState(initialFormData);
  const [students, setStudents] = useState<StudentData[]>([{ ...initialStudentData }]);
  const [kSheetStatus, setKSheetStatus] = useState<KSheetStatus>('no');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const today = format(new Date(), 'yyyy-MM-dd');
  const yesterday = format(subDays(new Date(), 1), 'yyyy-MM-dd');

  const addStudent = () => {
    if (students.length < 6) {
      setStudents([...students, { ...initialStudentData }]);
    }
  };

  const removeStudent = (index: number) => {
    if (students.length > 1) {
      setStudents(students.filter((_, i) => i !== index));
    }
  };

  const updateStudent = (index: number, selectedStudent: Student) => {
    const updatedStudents = [...students];
    updatedStudents[index] = {
      studentName: selectedStudent.name,
      grade: selectedStudent.grade,
      board: selectedStudent.board
    };
    setStudents(updatedStudents);
  };

  const validateForm = () => {
    if (!user) {
      toast.error('You must be logged in to submit updates');
      return false;
    }

    if (!formData.subject) {
      toast.error('Subject is required');
      return false;
    }

    if (!formData.chapterName.trim()) {
      toast.error('Chapter name is required');
      return false;
    }

    if (!formData.notes.trim()) {
      toast.error('Notes are required');
      return false;
    }

    if (!students[0].studentName) {
      toast.error('Please select at least one student');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm() || isSubmitting) return;

    try {
      setIsSubmitting(true);
      const now = Timestamp.now();
      
      const validStudents = students.filter(student => student.studentName);

      const updates = validStudents.map(student => ({
        teacherId: user!.id,
        teacherName: user!.role === 'admin' ? 'Admin' : user!.name,
        subject: formData.subject,
        chapterName: formData.chapterName.trim(),
        notes: formData.notes.trim(),
        chapterCompletion: formData.chapterCompletion,
        studentName: student.studentName.trim(),
        grade: student.grade.trim(),
        board: student.board || 'IGCSE',
        kSheetGiven: kSheetStatus,
        createdAt: now,
        updatedAt: now,
        timestamp: Timestamp.fromDate(new Date(formData.date))
      }));

      // Add the daily updates
      await Promise.all(updates.map(update => addDoc(collection(db, 'dailyUpdates'), update)));

      // Award points for daily update
      await addPoints('dailyUpdate');
      
      // Award additional points if K-Sheet was given
      if (kSheetStatus === 'yes') {
        await addPoints('kSheet');
      }

      toast.success(`Daily update${updates.length > 1 ? 's' : ''} submitted successfully`);
      
      // Reset form
      setStudents([{ ...initialStudentData }]);
      setFormData(initialFormData);
      setKSheetStatus('no');
    } catch (error) {
      console.error('Error submitting updates:', error);
      toast.error('Failed to submit updates. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-700 mb-2">Please Log In</h2>
          <p className="text-gray-500">You must be logged in to submit daily updates.</p>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-3xl mx-auto py-8 space-y-8">
      <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <CheckCircle className="w-6 h-6 text-accent mr-2" />
            <h2 className="text-xl font-bold text-secondary">Daily Update Form</h2>
          </div>
          <div className="text-sm text-gray-500">
            Submitting as: <span className="font-medium text-accent">
              {user.role === 'admin' ? 'Admin' : user.name}
            </span>
          </div>
        </div>

        <div className="space-y-6">
          {students.map((student, index) => (
            <div key={index} className="relative border border-gray-200 rounded-lg p-4">
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => removeStudent(index)}
                  className="absolute top-2 right-2 p-1 text-gray-400 hover:text-red-500"
                >
                  <X className="w-5 h-5" />
                </button>
              )}

              <div className="grid grid-cols-1 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Student {index + 1} {index === 0 && <span className="text-red-500">*</span>}
                  </label>
                  <StudentSelect
                    onSelect={(selectedStudent) => updateStudent(index, selectedStudent)}
                  />
                </div>

                {student.studentName && (
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Grade</label>
                      <input
                        type="text"
                        value={student.grade || ''}
                        className="input-field bg-gray-50"
                        disabled
                        readOnly
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">Board</label>
                      <input
                        type="text"
                        value={student.board || 'IGCSE'}
                        className="input-field bg-gray-50"
                        disabled
                        readOnly
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          {students.length < 6 && (
            <button
              type="button"
              onClick={addStudent}
              className="text-accent hover:text-accent/80 font-medium flex items-center"
            >
              <Plus className="w-4 h-4 mr-1" />
              Add another student
            </button>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Subject <span className="text-red-500">*</span>
            </label>
            <select
              value={formData.subject}
              onChange={(e) => setFormData({ ...formData, subject: e.target.value as Subject })}
              className="input-field"
              required
            >
              <option value="">Select a subject</option>
              {SUBJECTS.map(subject => (
                <option key={subject} value={subject}>{subject}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Chapter Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={formData.chapterName}
              onChange={(e) => setFormData({ ...formData, chapterName: e.target.value })}
              className="input-field"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Notes <span className="text-red-500">*</span>
            </label>
            <textarea
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              className="input-field min-h-[100px]"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date
            </label>
            <select
              value={formData.date}
              onChange={(e) => setFormData({ ...formData, date: e.target.value })}
              className="input-field"
            >
              <option value={today}>Today ({format(new Date(today), 'dd MMM yyyy')})</option>
              <option value={yesterday}>Yesterday ({format(new Date(yesterday), 'dd MMM yyyy')})</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-3">
              K-Sheet Given
            </label>
            <div className="flex gap-6">
              {(['no', 'textual', 'yes'] as const).map((status) => (
                <label
                  key={status}
                  className={`
                    relative flex flex-col items-center gap-2 cursor-pointer
                    transition-all duration-300 p-4 rounded-xl
                    ${kSheetStatus === status 
                      ? 'bg-primary/10 ring-2 ring-primary shadow-lg scale-105' 
                      : 'bg-gray-50 hover:bg-gray-100'
                    }
                  `}
                >
                  <input
                    type="radio"
                    name="kSheetStatus"
                    value={status}
                    checked={kSheetStatus === status}
                    onChange={(e) => setKSheetStatus(e.target.value as KSheetStatus)}
                    className="sr-only"
                  />
                  <div className={`
                    w-12 h-12 rounded-lg flex items-center justify-center
                    transition-all duration-300
                    ${kSheetStatus === status
                      ? 'bg-primary text-white'
                      : 'bg-white text-gray-400 border-2 border-gray-200'
                    }
                  `}>
                    {status === 'yes' && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                    {status === 'no' && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    )}
                    {status === 'textual' && (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                    )}
                  </div>
                  <span className={`
                    text-sm font-medium capitalize
                    transition-colors duration-300
                    ${kSheetStatus === status ? 'text-primary' : 'text-gray-600'}
                  `}>
                    {status}
                  </span>
                  <div className={`
                    absolute -inset-0.5 rounded-xl transition-opacity duration-300
                    bg-gradient-to-br from-primary/20 to-primary/5
                    ${kSheetStatus === status ? 'opacity-100' : 'opacity-0'}
                  `} />
                </label>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-6">
              Chapter Completion
            </label>
            <div className="chapter-progress">
              {[25, 50, 75, 100].map((value, index, array) => (
                <div key={value} className={`progress-point ${formData.chapterCompletion === value ? 'active' : ''}`}>
                  <button
                    type="button"
                    className={`progress-button ${formData.chapterCompletion >= value ? 'complete' : 'incomplete'}`}
                    onClick={() => setFormData({ ...formData, chapterCompletion: value })}
                  >
                    {value}%
                  </button>
                  {index < array.length - 1 && (
                    <div className={`progress-line ${formData.chapterCompletion >= array[index + 1] ? 'complete' : ''}`} />
                  )}
                  <span className="progress-label">
                    {value === 25 ? 'Started' :
                     value === 50 ? 'Halfway' :
                     value === 75 ? 'Almost Done' : 'Complete'}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full mt-8 btn-primary flex items-center justify-center"
        >
          <CheckCircle className="w-5 h-5 mr-2" />
          {isSubmitting ? 'Submitting...' : 'Submit Update'}
        </button>
      </div>
    </form>
  );
}