import React, { useState } from 'react';
import { LogIn, Lock, Mail } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await signIn(email, password);
    } catch (error) {
      // Error is handled in useAuth hook
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary/20 via-white to-accent/20 flex items-center justify-center p-4">
      <div className="max-w-md w-full">
        <div className="card backdrop-blur-sm bg-white/90 p-8 form-appear">
          <div className="flex flex-col items-center justify-center mb-12">
            <img 
              src="https://static.wixstatic.com/shapes/abaee8_dc6d6d64fba440848d2b9769e4f2e998.svg" 
              alt="Inkstall Logo" 
              className="w-48 h-auto mb-6"
            />
            <p className="mt-2 text-gray-600">Education Dashboard</p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-field pl-12"
                required
                placeholder="Enter your email"
              />
            </div>

            <div className="relative">
              <Lock className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-field pl-12"
                required
                placeholder="Enter password"
              />
            </div>

            <button type="submit" className="btn-primary w-full group">
              <LogIn className="w-5 h-5 mr-2 inline-block group-hover:translate-x-1 transition-transform" />
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}