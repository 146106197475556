import React, { useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { format } from 'date-fns';
import { Calendar } from 'lucide-react';
import toast from 'react-hot-toast';

interface LeaveFormData {
  teacherId: string;
  teacherName: string;
  startDate: string;
  endDate: string;
  reason: string;
  status: 'pending' | 'approved' | 'rejected';
  leaveType?: 'paid' | 'unpaid';
  appliedAt: Date;
}

export default function LeaveForm() {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    reason: ''
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user?.id || !user?.name) {
      toast.error('User information not available');
      return;
    }

    try {
      const leaveData: LeaveFormData = {
        teacherId: user.id,
        teacherName: user.name,
        startDate: formData.startDate,
        endDate: formData.endDate,
        reason: formData.reason,
        status: 'pending',
        appliedAt: new Date()
      };

      await addDoc(collection(db, 'leaveRequests'), leaveData);
      
      setFormData({
        startDate: '',
        endDate: '',
        reason: ''
      });
      
      toast.success('Leave request submitted successfully');
    } catch (error) {
      console.error('Error submitting leave request:', error);
      toast.error('Failed to submit leave request');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold text-secondary mb-6 flex items-center">
        <Calendar className="w-6 h-6 mr-2 text-primary" />
        Apply for Leave
      </h2>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Teacher Name
            </label>
            <input
              type="text"
              value={user?.name || ''}
              className="input-field bg-gray-100"
              disabled
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Start Date
            </label>
            <input
              type="date"
              value={formData.startDate}
              onChange={(e) => setFormData(prev => ({ ...prev, startDate: e.target.value }))}
              min={format(new Date(), 'yyyy-MM-dd')}
              className="input-field"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              End Date
            </label>
            <input
              type="date"
              value={formData.endDate}
              onChange={(e) => setFormData(prev => ({ ...prev, endDate: e.target.value }))}
              min={formData.startDate || format(new Date(), 'yyyy-MM-dd')}
              className="input-field"
              required
            />
          </div>

          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Reason for Leave
            </label>
            <textarea
              value={formData.reason}
              onChange={(e) => setFormData(prev => ({ ...prev, reason: e.target.value }))}
              className="input-field min-h-[100px]"
              required
              placeholder="Please provide a reason for your leave request..."
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="btn-primary"
          >
            Submit Leave Request
          </button>
        </div>
      </form>
    </div>
  );
}
